import { AppConstants } from "./appconstants";
import { CreateApp_TemplateTypes } from "./enums";

export const environmentTabs = [
        {
            displayName:"Overview",
            hashName:"#overview",
            type:[0, 1, 2, 3, 4]
        },
        {
            displayName:"Insight",
            hashName:"#insight",
            type: [0, 1, 2, 3]
        },
        {
            displayName:"CI/CD",
            hashName:"#cicd",
            type: [1]
        },
        {
            displayName:"Addons",
            hashName:"#addons",
            type: [0, 1, 2, 4]
        },
        {
            displayName:"Jobs",
            hashName:"#jobs",
            type: [1, 2]
        },
        {
            displayName:"Backup",
            hashName:"#backup",
            type: [1, 2, 3]
        },
        {
            displayName:"Alert",
            hashName:"#alert",
            type: [0, 1, 2]
        },
        {
            displayName:"Settings",
            hashName:"#settings",
            type: [0, 1, 2, 4]
    }
]

export const ProjectSettingTabs = ["general", "variables", "subscription"];
export function EnvSettingTabs(tabname, envDetails) {
    let tabs = [];
    const tabNames = AppConstants.EnvSettingsTabs;
    const service_type = envDetails.service_type;

    if (tabname === "#settings") {
        tabs = [tabNames.domain, tabNames.permissions, tabNames.resources, tabNames.storage, tabNames.logger, tabNames.variables];
        if (envDetails.external_secret?.enabled){
            tabs.push(tabNames.externalsecret)
        }
        tabs.push(tabNames.scheduler)
        tabs.push(tabNames.network)
        if (service_type === CreateApp_TemplateTypes.git) {
            tabs.push(tabNames.startup);
            //tabs.push(tabNames.scripts);
        }
        else if (service_type === CreateApp_TemplateTypes.op) {
            tabs.shift();
            tabs.pop();
        }
    }
    else if (tabname === "#backup") {
        tabs = [tabNames.snapshot, tabNames.restore, tabNames.settings];
    }
    else if (tabname === "#cicd") { 
        if (service_type === 2)
            tabs = [
                tabNames.cd,
                tabNames.settings];
        else
            tabs = [tabNames.ci, tabNames.cistats, tabNames.cd, tabNames.settings, tabNames.scripts,tabNames.ciVars];
    }
    else if (tabname === "#alert") {
        tabs = [tabNames.alertdashboard, tabNames.alertrules, tabNames.alertnoti, tabNames.alerttemplates, tabNames.alerthistory];
    }
    return tabs;
}

export function HelmEnvSettingTabs(tabname) {
    let tabs = [];
    if (tabname === "#settings") {
        tabs = ["scheduler"];
    }
    else if (tabname === "#backup") {
        tabs = ["snapshot", "restore", "settings"];
    }
    return tabs;
}

export function OrgSettingTabs(tabname) {
    let tabs = [];
    if (tabname === "#admin") {
        tabs = ["general", "subscription", "resource", "plugin", "chartRepos", "chartCatalog"];
    }
    if (tabname === "#cluster"){
        tabs = ["clusters", "registry", "dns", "security"];
    }
    return tabs;
}