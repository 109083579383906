const PREFIX = '@CreateApp'
export const FETCH_PLUGINS_LIST = `${ PREFIX }/FETCH_PLUGINS_LIST`
export const FETCH_PLUGINS_LIST_SUCCESS = `${ PREFIX }/FETCH_PLUGINS_LIST_SUCCESS`
export const FETCH_MORE_PLUGINS_LIST_SUCCESS = `${PREFIX}/FETCH_MORE_PLUGINS_LIST_SUCCESS`;
export const FETCH_PLUGINS_LIST_FAILURE = `${ PREFIX }/FETCH_PLUGINS_LIST_FAILURE`
export const FETCH_PLUGINS_VERSIONS = `${ PREFIX }/FETCH_PLUGINS_VERSIONS`
export const FETCH_PLUGINS_VERSIONS_SUCCESS = `${ PREFIX }/FETCH_PLUGINS_VERSIONS_SUCCESS`
export const FETCH_PLUGINS_VERSIONS_FAILURE = `${ PREFIX }/FETCH_PLUGINS_VERSIONS_FAILURE`
export const BEGIN_LOADING_INDICATOR = `${ PREFIX }/BEGIN_LOADING_INDICATOR`
export const END_LOADING_INDICATOR = `${ PREFIX }/END_LOADING_INDICATOR`
export const FETCH_REGIONS= `${ PREFIX }/FETCH_REGIONS`
export const FETCH_REGIONS_SUCCESS = `${ PREFIX }/FETCH_REGIONS_SUCCESS`
export const FETCH_REGIONS_FAILURE = `${ PREFIX }/FETCH_REGIONS_FAILURE`

export const FETCH_REGION= `${ PREFIX }/FETCH_REGION`
export const FETCH_REGION_SUCCESS = `${ PREFIX }/FETCH_REGION_SUCCESS`
export const FETCH_REGION_FAILURE = `${ PREFIX }/FETCH_REGION_FAILURE`

export const FETCH_CLUSTER= `${ PREFIX }/FETCH_CLUSTER`
export const FETCH_CLUSTER_SUCCESS = `${ PREFIX }/FETCH_CLUSTER_SUCCESS`
export const FETCH_CLUSTER_FAILURE = `${ PREFIX }/FETCH_CLUSTER_FAILURE`

//export const CREATE_APP_DEPLOY  = `${ PREFIX }/CREATE_APP_DEPLOY`
export const CLEAR_PLUGINS_VERSIONS = `${ PREFIX }/CLEAR_PLUGINS_VERSIONS`
export const CLEAR_PLUGINS_LIST_DATA = `${ PREFIX }/CLEAR_PLUGINS_LIST_DATA`

const fetchPluginsList = (support_ci,page, size, search) => ({
    type: FETCH_PLUGINS_LIST,
    data: { support_ci,page, size, search }
})

const fetchRegionsList = () => ({
    type: FETCH_REGIONS,
})

const fetchPluginVersions = (id) => ({
    type: FETCH_PLUGINS_VERSIONS,
    data: { id }
})

const beginLoading = () => ({
    type: BEGIN_LOADING_INDICATOR,
})

const endLoading = () => ({
    type: END_LOADING_INDICATOR,
})

const clearPlugInVersions = () => ({
    type: CLEAR_PLUGINS_VERSIONS
})

const clearPlugInListData = () => ({
    type: CLEAR_PLUGINS_LIST_DATA
})
const fetchRegionList = () => ({
    type: FETCH_REGION,
})
const fetchClusterList = (region) => ({
    type: FETCH_CLUSTER,
    data: {region}
})

export {
    fetchPluginsList,
    fetchPluginVersions,
    beginLoading,
    endLoading,
    fetchRegionsList,
    clearPlugInVersions,
    clearPlugInListData,
    fetchRegionList,
    fetchClusterList,
}
