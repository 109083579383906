import { 
  FETCH_ENVIRONMENT_DETAIL,
  FETCH_ENVIRONMENT_DETAIL_SUCCESS,
  FETCH_ENVIRONMENT_DETAIL_FAILURE,
  DELETE_ENVIRONMENT,
  DELETE_ENVIRONMENT_FAILURE,
  DELETE_ENVIRONMENT_SUCCESS,
  FETCH_ENV_VARIABLES_SUCCESS,
  FETCH_RESOURCES,
  FETCH_RESOURCES_SUCCESS,
  FETCH_RESOURCES_FAILURE,
  CREATE_ENVIRONMENT,
  CREATE_ENVIRONMENT_SUCCESS,
  CREATE_ENVIRONMENT_FAILURE,
  FETCH_ENV_LOGS_SUCCESS,
  FETCH_ENV_LOGS_STATUS_SUCCESS,
  FETCH_PLUGIN_VERSION_CONFIG,
  FETCH_PLUGIN_VERSION_CONFIG_SUCCESS,
  FETCH_PLUGIN_VERSION_CONFIG_FAILURE,
  CLEAR_ENV_LOGS_STATUS,
  CLEAR_ENV_ACTIVITIES,
  CLEAR_ENV_INSIGHTS,
  CLEAR_ENVIRONMENT_DETAIL,
  FETCH_ENVIRONMENT_INSIGHT_SUCCESS,
  FETCH_ENVIRONMENT_OVERVIEW_INSIGHT_SUCCESS,
  FETCH_ENV_STATE_SUCCESS,
  CHANGE_ENV_TAB_VAL, 
  UPDATE_ENVIRONMENT,
  UPDATE_ENVIRONMENT_SUCCESS,
  UPDATE_ENVIRONMENT_FAILURE,
  FETCH_ENV_ROLE_SUCCESS,
  FETCH_ACTIVITY_LOGS_SUCCESS,
  UPDATE_ACTIVITY_LOGS,
  RECREATE_ENV,
  RECREATE_ENV_SUCCESS,
  RECREATE_ENV_FAILURE,
  FETCH_ADDONS,
  FETCH_ADDONS_SUCCESS,
  FETCH_ADDONS_FAILURE,
  FETCH_ADDON_INFO,
  FETCH_ADDON_INFO_SUCCESS,
  FETCH_ADDON_INFO_FAILURE,
  FETCH_INSTALLED_ADDONS,
  FETCH_INSTALLED_ADDONS_SUCCESS,
  FETCH_INSTALLED_ADDONS_FAILURE,
  INSTALL_ADDON,
  INSTALL_ADDON_FAILURE,
  INSTALL_ADDON_SUCCESS,
  MODIFY_ADDON,
  MODIFY_ADDON_FAILURE,
  MODIFY_ADDON_SUCCESS,
  UNINSTALL_ADDON, 
  UNINSTALL_ADDON_FAILURE,
  UNINSTALL_ADDON_SUCCESS,
  UPDATE_ENV_VARIABLES,
  UPDATE_ENV_VARIABLES_SUCCESS,
  UPDATE_ENV_VARIABLES_FAILURE,
  FETCH_ADDONS_STATUS, 
  FETCH_ADDONS_STATUS_FAILURE, 
  FETCH_ADDONS_STATUS_SUCCESS,
  FETCH_HPA_INSIGHT,
  FETCH_HPA_INSIGHT_FAILURE,
  FETCH_HPA_INSIGHT_SUCCESS,
  FETCH_ENVIRONMENT_INSIGHT_FAILURE,
  FETCH_ENVIRONMENT_INSIGHT,
  FETCH_ENVIRONMENT_OVERVIEW_INSIGHT_FAILURE,
  FETCH_ENVIRONMENT_OVERVIEW_INSIGHT,
  FETCH_ENV_LOGS_FAILURE,
  FETCH_ENV_LOGS,
  FETCH_ENV_LOGS_STATUS_FAILURE,
  FETCH_ENV_LOGS_STATUS,
  FETCH_ENV_VARIABLES_FAILURE,
  FETCH_ENV_VARIABLES,
  SET_CURRENT_ENVIRONMENT,
  CLEAR_CURRENT_ENVIRONMENT,
  FETCH_ENVIRONMENT_PODS,
  FETCH_ENVIRONMENT_PODS_SUCCESS,
  FETCH_ENVIRONMENT_PODS_FAILURE,
  START_ENV,
  START_ENV_SUCCESS,
  START_ENV_FAILURE,
  STOP_ENV,
  STOP_ENV_FAILURE,
  STOP_ENV_SUCCESS,
  UPDATE_ADDON_STATUS,
  CLEAR_PLUGIN_VERSION_CONFIG,
  FETCH_ENV_WORKFLOW,
  FETCH_ENV_WORKFLOW_FAILURE,
  FETCH_ENV_WORKFLOW_SUCCESS,
  ROLLBACK_ENV, 
  ROLLBACK_ENV_FAILURE,
  ROLLBACK_ENV_SUCCESS,
  GET_STORAGE,
  GET_STORAGE_FAILURE,
  GET_STORAGE_SUCCESS,
  CREATE_STORAGE,
  CREATE_STORAGE_FAILURE,
  CREATE_STORAGE_SUCCESS,
  DELETE_STORAGE,
  DELETE_STORAGE_FAILURE,
  DELETE_STORAGE_SUCCESS,
  EDIT_STORAGE,
  EDIT_STORAGE_FAILURE,
  EDIT_STORAGE_SUCCESS,
  CLEAR_STORAGE_DATA,
  CLEAR_ADDON_STATUS,
  UPDATE_ENV_STATUS,
  FETCH_ADDON_CATEGORIES_SUCCESS,
  INITIATE_FETCH_PACKAGE_STATUS,
  INITIATE_FETCH_PACKAGE_STATUS_SUCCESS,
  INITIATE_FETCH_PACKAGE_STATUS_FAILURE,
  UPDATE_PACKAGE_STATUS,
  CLEAR_ENV_OVERVIEW_INFO,
  CLEAR_ROLLBACK_POPUP_INFO,
  UPDATE_CUSTOM_DOMAIN,
  UPDATE_CUSTOM_DOMAIN_SUCCESS,
  UPDATE_CUSTOM_DOMAIN_FAILURE,
  UPDATE_DOMAIN_STATUS,
  UPDATE_ENV_SERVICE_DETAILS,
  UPDATE_ENV_SCHEDULE,
  UPDATE_ENV_SCHEDULE_SUCCESS,
  UPDATE_ENV_SCHEDULE_FAILURE,
  UPDATE_ENV_SCHEDULE_LOG_SUCCESS,
  UPDATE_FILE_MANAGER_STATUS,
  UPDATE_FILE_MANAGER_STATUS_SUCCESS,
  UPDATE_FILE_MANAGER_STATUS_FAILURE,
  UPDATE_GIT_BRANCH,
  UPDATE_GIT_BRANCH_SUCCESS,
  UPDATE_GIT_BRANCH_FAILURE,
  CLONE_ENVIRONMENT,
  CLONE_ENVIRONMENT_SUCCESS,
  CLONE_ENVIRONMENT_FAILURE,
  // FETCH_EXTERNAL_SECRET,
  // FETCH_EXTERNAL_SECRET_SUCCESS,
  // FETCH_EXTERNAL_SECRET_FAILURE,
  UPDATE_EXTERNAL_SECRET_SUCCESS,
  UPDATE_EXTERNAL_SECRET,
  UPDATE_EXTERNAL_SECRET_FAILURE,
  FETCH_SECRET_LOGS_SUCCESS,
  CLEAR_ADDON_INFO,
  UPDATE_ENVIRONMENT_ERROR_STATUS,
  FETCH_SYSTEM_VARIABLE_CONFIG_SUCCESS,
  FETCH_LOGGER_CONFIG_SUCCESS,
  FETCH_SCANNER_PLUGINS,
  FETCH_SCANNER_PLUGINS_SUCCESS,
  FETCH_SCANNER_PLUGINS_FAILURE,
  FETCH_SPECIFIC_REPORT,
  FETCH_SPECIFIC_REPORT_SUCCESS,
  FETCH_SPECIFIC_REPORT_FAILURE,
  FETCH_SCAN_LIST_SUCCESS,
  FETCH_SCAN_LIST,
  FETCH_SCAN_LIST_FAILURE,
  CLEAR_SPECIFIC_REPORT,
  CLEAR_SCAN_LIST,
  TOGGLE_EXTERNAL_URL,
  TOGGLE_EXTERNAL_URL_SUCCESS,
  TOGGLE_EXTERNAL_URL_FAILURE,
  UPDATE_IP_WHITELISTING,
  UPDATE_IP_WHITELISTING_SUCCESS,
  UPDATE_IP_WHITELISTING_FAILURE
} from './actions';
import initialState from './initialState';

let envState

const reducer = (state = initialState, payload ) => {
  switch (payload.type) {
    case FETCH_ENVIRONMENT_DETAIL:
      return {
        ...state
      }
    case FETCH_ENVIRONMENT_DETAIL_SUCCESS:
      return {
        ...state,
        environmentDetails : payload.data.environment,
        environmentMetadata: payload.data.metadata,
        environmentOverview: payload.data.overview,
        isDataNotFound: false
      }
    case FETCH_ENVIRONMENT_DETAIL_FAILURE:
      return{
        ...state,
        isDataNotFound: true
      }
    case CLEAR_ENVIRONMENT_DETAIL:
      return {
        ...state,
        environmentDetails : {},
        environmentMetadata: {},
        environmentOverview: {},
        envTabValue: 0, 
        envRole: {},
        environmentVariables : initialState.environmentVariables,
        environmentOverviewInsights: initialState.environmentOverviewInsights,
        environmentState: initialState.environmentState,
        activityLogs: initialState.activityLogs,
        realTimeActivityLogs: initialState.realTimeActivityLogs,
        addons: initialState.addons,
        environmentInsights:initialState.environmentInsights,
        hpaInsight: initialState.hpaInsight,
        currentEnvironment: initialState.currentEnvironment,
        environmentPods: initialState.environmentPods,
        installedAddons:  initialState.installedAddons,
        addonsStatus:  initialState.addonsStatus,
        addonInfo: initialState.addonInfo,
        addonCategories: initialState.addonCategories,
        packageStatus: initialState.packageStatus,
        envServiceDetails: initialState.envServiceDetails
      }
    case DELETE_ENVIRONMENT:
      return {
        ...state,
        deletingEnv: true
      }
    case DELETE_ENVIRONMENT_SUCCESS:
      return {
        ...state,
        environmentDetails : {},
        environmentMetadata: {},
        environmentOverview: {},
        deletingEnv: false
      }
    case DELETE_ENVIRONMENT_FAILURE:
      return {
        ...state,
        deletingEnv: false
      }
    case FETCH_ENV_VARIABLES:
      return {
        ...state,
        fetchingEnvVar : true
      }
    case FETCH_ENV_VARIABLES_SUCCESS:
      return {
        ...state,
        environmentVariables : payload.data,
        fetchingEnvVar : false
      }
    case FETCH_ENV_VARIABLES_FAILURE:
      return {
        ...state,
        fetchingEnvVar : false
      }
    case FETCH_RESOURCES:
      return{
        ...state,
        fetchingResources: true
      }
    case FETCH_RESOURCES_SUCCESS:
      return{
        ...state,
        resourcesList: payload.data,
        fetchingResources: false
      }
    case FETCH_RESOURCES_FAILURE:
      return{
        ...state,
        fetchingResources: false
      }
    case CREATE_ENVIRONMENT : 
      return {
        ...state,
        creatingEnv: true
      }
    case CREATE_ENVIRONMENT_SUCCESS : 
      return {
        ...state,
        creatingEnv: false,
      }
    case CREATE_ENVIRONMENT_FAILURE : 
      return {
        ...state,
        creatingEnv: false
      }
    case FETCH_ENV_LOGS:
      return{
        ...state,
        fetchingEnvLogs: true
      }
    case FETCH_ENV_LOGS_SUCCESS:
      return{
        ...state,
        startFetchLogs: payload.data,
        //fetchingEnvLogs: false
      }
    case FETCH_ENV_LOGS_FAILURE:
      return{
        ...state,
        fetchingEnvLogs: false
      }
    case FETCH_ENV_LOGS_STATUS:
      return{
        ...state,
        //fetchingEnvLogs: true
      }
    case FETCH_ENV_LOGS_STATUS_SUCCESS:
      return{
        ...state,
        environmentStatus: payload.data,
        fetchingEnvLogs: false

      }
    case FETCH_ENV_LOGS_STATUS_FAILURE:
      return{
        ...state,
        fetchingEnvLogs: false
      }
    case CLEAR_ENV_LOGS_STATUS:
      return{
        ...state,
        environmentStatus: []
      }
    case CLEAR_ENV_ACTIVITIES:
      return{
        ...state,
        activityLogs: [],
        realTimeActivityLogs: []
      }
    case CLEAR_ENV_INSIGHTS:
      return{
        ...state,
        environmentInsights: initialState.environmentInsights
      }
    case FETCH_PLUGIN_VERSION_CONFIG:
      return {
        ...state,
        fetchingPluginVersionConfig: true
      }
    case FETCH_PLUGIN_VERSION_CONFIG_SUCCESS:
      return {
        ...state,
        pluginVersionConfig: payload.data,
        fetchingPluginVersionConfig: initialState.fetchingPluginVersionConfig
      }
    case FETCH_PLUGIN_VERSION_CONFIG_FAILURE:
      return {
        ...state,
        fetchingPluginVersionConfig: initialState.fetchingPluginVersionConfig
      }
    case CLEAR_PLUGIN_VERSION_CONFIG:
      return {
        ...state,
        pluginVersionConfig: initialState.pluginVersionConfig,
        fetchingPluginVersionConfig: initialState.fetchingPluginVersionConfig
      }
    case FETCH_ENVIRONMENT_INSIGHT:
        return {
          ...state,
          fetchingEnvInsight : true
      }
    case FETCH_ENVIRONMENT_INSIGHT_SUCCESS:
        return {
          ...state,
          environmentInsights : payload.data,
          fetchingEnvInsight : false

      }
    case FETCH_ENVIRONMENT_INSIGHT_FAILURE:
        return {
          ...state,
          fetchingEnvInsight : false
      }
    case FETCH_ENVIRONMENT_OVERVIEW_INSIGHT:
        return {
          ...state,
          fetchingEnvOverviewInsight : true
      }
    case FETCH_ENVIRONMENT_OVERVIEW_INSIGHT_SUCCESS:
        return {
          ...state,
          environmentOverviewInsights : payload.data,
          fetchingEnvOverviewInsight : false

      }
    case FETCH_ENVIRONMENT_OVERVIEW_INSIGHT_FAILURE:
        return {
          ...state,
          fetchingEnvOverviewInsight : false
      }
    case FETCH_ENV_STATE_SUCCESS:
        return {
          ...state,
          environmentState : payload.data
      }
    case UPDATE_ENV_STATUS:
        if(state.environmentState){    
          envState =  {...state.environmentState, state: payload.data.status}
        }else{
          envState = {State: payload.data.status}
        }
        return {
          ...state,
          environmentState : envState
        }
        
    case CHANGE_ENV_TAB_VAL:
      return {
        ...state, 
        envTabValue: payload.data.val
      }
    case UPDATE_ENVIRONMENT:
      return {
        ...state,
        updatingEnv: true
      }
    case UPDATE_ENVIRONMENT_SUCCESS:
      return {
        ...state, 
        updatingEnv: false
      }
    case UPDATE_ENVIRONMENT_FAILURE:
      return {
        ...state, 
        updatingEnv: false
      }
    case FETCH_ENV_ROLE_SUCCESS:
      return {
        ...state, 
        envRole: payload.data
      }
    case FETCH_ACTIVITY_LOGS_SUCCESS:
      return {
        ...state,
        activityLogs: payload.data
      }
    case UPDATE_ACTIVITY_LOGS:
      return {
        ...state,
        realTimeActivityLogs: payload.data.logs
      }
    case RECREATE_ENV:
      return {
        ...state,
        reCreationTriggered : true
      }
    case RECREATE_ENV_SUCCESS:
      return {
        ...state,
        reCreationTriggered : false
      }
    case RECREATE_ENV_FAILURE:
      return {
        ...state,
        reCreationTriggered : false
      }
    case FETCH_ADDON_INFO:
      return {
        ...state,
        fetchingAddon : true
      }
    case FETCH_ADDON_INFO_SUCCESS:
      return {
        ...state,
        addonInfo: payload.data?.environment ?? payload.data,
        addonMetaData: payload.data?.metadata,
        fetchingAddon : false
      }
    case FETCH_ADDON_INFO_FAILURE:
      return {
        ...state,
        fetchingAddon : false,
      }
    case CLEAR_ADDON_INFO:
      return {
        ...state,
        addonInfo:initialState.addonInfo
      }
    case FETCH_ADDONS:
      return {
        ...state,
        addonsLoading : true
      }
    case FETCH_ADDONS_SUCCESS:
      return {
        ...state,
        addons: payload.data,
        addonsLoading : false
      }
    case FETCH_ADDONS_FAILURE:
      return {
        ...state,
        addonsLoading : false,
        addonsError: payload.error,
      }
    case FETCH_INSTALLED_ADDONS:
      return {
        ...state,
        addonsLoading : true
      }
    case FETCH_INSTALLED_ADDONS_SUCCESS:
      return {
        ...state,
        installedAddons: payload.data,
        addonsLoading : false
      }
    case FETCH_INSTALLED_ADDONS_FAILURE:
      return {
        ...state,
        addonsLoading : false,
      }
    case INSTALL_ADDON:
      return {
        ...state,
        installingAddon: true,
      }
    case INSTALL_ADDON_SUCCESS:
      return {
        ...state,
        installingAddon: false,
      }
    case INSTALL_ADDON_FAILURE:
      return {
        ...state,
        installingAddon: false,
        installError: payload.error
      }
    case MODIFY_ADDON:
      return {
        ...state,
        modifyingAddon: true,
      }
    case MODIFY_ADDON_SUCCESS:
      return {
        ...state,
        modifyingAddon: false,
      }
    case MODIFY_ADDON_FAILURE:
      return {
        ...state,
        modifyingAddon: false,
      }
    case UNINSTALL_ADDON:
      return {
        ...state,
        uninstallingAddon: true,
      }
    case UNINSTALL_ADDON_SUCCESS:
      return {
        ...state,
        uninstallingAddon: false,
      }
    case UNINSTALL_ADDON_FAILURE:
      return {
        ...state,
        uninstallingAddon: false
        //uninstallError: payload.error
      }
    case UPDATE_ENV_VARIABLES:
      return {
        ...state,
        updatingVariables: true
      }
    case UPDATE_ENV_VARIABLES_SUCCESS:
      return {
        ...state,
        updatingVariables: false
      }
    case UPDATE_ENV_VARIABLES_FAILURE:
      return {
        ...state,
        updatingVariables: false
      }
    case FETCH_ADDONS_STATUS:
      return {
        ...state,
        fetchingAddonsStatus: true
      }
    case FETCH_ADDONS_STATUS_SUCCESS:
      return {
        ...state,
        fetchingAddonsStatus: false,
        addonsStatus: payload.data
      }
    case FETCH_ADDONS_STATUS_FAILURE:
      return {
        ...state,
        fetchingAddonsStatus: false,
        addonsStatus: initialState.addonsStatus
      }
    case FETCH_HPA_INSIGHT:
      return {
        ...state,
        fetchingHpaInsight: true,
      }
    case FETCH_HPA_INSIGHT_SUCCESS:
      return {
        ...state,
        fetchingHpaInsight: false,
        hpaInsight: payload.data
      }
    case FETCH_HPA_INSIGHT_FAILURE:
      return {
        ...state,
        fetchingHpaInsight: false,
        // hpaInsight: initialState.addonsStatus
      }
    case SET_CURRENT_ENVIRONMENT:
      return {
        ...state,
        currentEnvironment: payload.data.id
      }
    case CLEAR_CURRENT_ENVIRONMENT:
      return {
        ...state,
        currentEnvironment: initialState.currentEnvironment
      }
    case FETCH_ENVIRONMENT_PODS:
      return {
        ...state,
        fetchingPods: true
      }
    case FETCH_ENVIRONMENT_PODS_SUCCESS:
      return {
        ...state,
        environmentPods: payload.data,
        fetchingPods: false
      }
    case FETCH_ENVIRONMENT_PODS_FAILURE:
      return {
        ...state,
        fetchingPods: false
      }
    case START_ENV:
      return {
        ...state,
        startingEnv: true,
      }
    case START_ENV_SUCCESS:
      return {
        ...state,
        startingEnv: false,
      }
    case START_ENV_FAILURE:
      return {
        ...state,
        startingEnv: false,
      }
    case STOP_ENV:
      return {
        ...state,
        stoppingEnv: true,
      }
    case STOP_ENV_SUCCESS:
      return {
        ...state,
        stoppingEnv: false,
      }
    case STOP_ENV_FAILURE:
      return {
        ...state,
        stoppingEnv: false,
      }
    case FETCH_ENV_WORKFLOW:
      return {
        ...state,
        fetchingBuildImages: true,
      }
    case FETCH_ENV_WORKFLOW_SUCCESS:
      return {
        ...state,
        fetchingBuildImages: false,
        buildImages: payload.data
      }
    case FETCH_ENV_WORKFLOW_FAILURE:
      return {
        ...state,
        fetchingBuildImages: false,
      }
    case ROLLBACK_ENV:
      return {
        ...state,
        rollingBack: true,
      }
    case ROLLBACK_ENV_SUCCESS:
      return {
        ...state,
        rollingBack: false,
      }
    case ROLLBACK_ENV_FAILURE:
      return {
        ...state,
        rollingBack: false,
      }
    case UPDATE_ADDON_STATUS:
      return {
        ...state,
        wsAddonStatus: payload.data,
      }
    case GET_STORAGE:
      return {
        ...state,
        fetchingStorage: true,
      }
    case GET_STORAGE_SUCCESS:
      return {
        ...state,
        fetchingStorage: false,
        envStorage: payload.data
      }
    case GET_STORAGE_FAILURE:
      return {
        ...state,
        fetchingStorage: false,
      }
    case CREATE_STORAGE:
      return {
        ...state,
        creatingStorage: true,
      }
    case CREATE_STORAGE_SUCCESS:
      return {
        ...state,
        creatingStorage: false,
      }
    case CREATE_STORAGE_FAILURE:
      return {
        ...state,
        creatingStorage: false,
      }
    case EDIT_STORAGE:
      return {
        ...state,
        editingStorage: true,
      }
    case EDIT_STORAGE_SUCCESS:
      return {
        ...state,
        editingStorage: false,
      }
    case EDIT_STORAGE_FAILURE:
      return {
        ...state,
        editingStorage: false,
      }
    case DELETE_STORAGE:
      return {
        ...state,
        deletingStorage: true,
      }
    case DELETE_STORAGE_SUCCESS:
      return {
        ...state,
        deletingStorage: false,
      }
    case DELETE_STORAGE_FAILURE:
      return {
        ...state,
        deletingStorage: false,
      }
    case INITIATE_FETCH_PACKAGE_STATUS:
      return {
        ...state,
        fetchingPackageStatus: true,
      }
    case INITIATE_FETCH_PACKAGE_STATUS_SUCCESS:
      return {
        ...state,
        fetchingPackageStatus: false,
      }
    case INITIATE_FETCH_PACKAGE_STATUS_FAILURE:
      return {
        ...state,
        fetchingPackageStatus: false,
      }
    case UPDATE_PACKAGE_STATUS:
      return {
        ...state,
        packageStatus: payload.data?.status,
      }
    case CLEAR_STORAGE_DATA:
      return {
        ...state,
        envStorage: initialState.envStorage,
      }
    case CLEAR_ADDON_STATUS:
      return {
        ...state,
        addons: initialState.addons,
        installedAddons: initialState.installedAddons,
        wsAddonStatus: initialState.wsAddonStatus,
      }
    case CLEAR_ENV_OVERVIEW_INFO:
      return {
        ...state,
        hpaInsight: initialState.hpaInsight,
        activityLogs: initialState.activityLogs,
      }
    case CLEAR_ROLLBACK_POPUP_INFO:
      return {
        ...state,
        buildImages: initialState.buildImages,
      }
    case FETCH_ADDON_CATEGORIES_SUCCESS:
      return {
        ...state,
        addonCategories: payload.data,
        // addonCategories: [{ title: "cat 1" }, { title: "cat 2" }, { title: "databaese" }, { title: "utility" }]
      }
    case UPDATE_CUSTOM_DOMAIN:
      return {
        ...state,
        updatingCustomDomain: true,
      }
    case UPDATE_CUSTOM_DOMAIN_SUCCESS:
      return {
        ...state,
        updatingCustomDomain: false,
      }
    case UPDATE_CUSTOM_DOMAIN_FAILURE:
      return {
        ...state,
        updatingCustomDomain: false,
      }
    case UPDATE_DOMAIN_STATUS:
      return {
        ...state,
        domainStatus: payload.data.status
      }
    case UPDATE_ENV_SERVICE_DETAILS:
      return {
        ...state,
        envServiceDetails: payload.data.payload
      }
    case UPDATE_ENV_SCHEDULE:
      return {
        ...state,
        updatingEnvSchedule: true
      }
    case UPDATE_ENV_SCHEDULE_SUCCESS:
      return {
        ...state,
        updatingEnvSchedule: false
      }
    case UPDATE_ENV_SCHEDULE_FAILURE:
      return {
        ...state,
        updatingEnvSchedule: false
      }
    case UPDATE_ENV_SCHEDULE_LOG_SUCCESS:
      return {
        ...state,
        envScheduleLogs: payload.data
      }
    case UPDATE_FILE_MANAGER_STATUS:
      return {
        ...state,
        updatingFileManagerStatus: true,
      }
    case UPDATE_FILE_MANAGER_STATUS_SUCCESS:
      return {
        ...state,
        updatingFileManagerStatus: false,
      }
    case UPDATE_FILE_MANAGER_STATUS_FAILURE:
      return {
        ...state,
        updatingFileManagerStatus: false,
      }
    case UPDATE_GIT_BRANCH:
      return {
        ...state,
        updatingGitBranch: true,
      }
    case UPDATE_GIT_BRANCH_SUCCESS:
      return {
        ...state,
        updatingGitBranch: false,
        environmentDetails : payload.data,
      }
    case UPDATE_GIT_BRANCH_FAILURE:
      return {
        ...state,
        updatingGitBranch: false,
      }
    case CLONE_ENVIRONMENT:
      return {
        ...state,
        cloningEnvironment: true
      }
    case CLONE_ENVIRONMENT_SUCCESS:
      return {
        ...state,
        cloningEnvironment: false
      }
    case CLONE_ENVIRONMENT_FAILURE:
      return {
        ...state,
        cloningEnvironment: false
      }
    case UPDATE_EXTERNAL_SECRET:
      return {
        ...state,
        updatingSecret: true
      }
    case UPDATE_EXTERNAL_SECRET_SUCCESS:
      return {
        ...state,
        updatingSecret: false
      }
    case UPDATE_EXTERNAL_SECRET_FAILURE:
      return {
        ...state,
        updatingSecret: false
      }
    case FETCH_SECRET_LOGS_SUCCESS:
      return {
        ...state,
        secretLogs: payload.data
      }

    case UPDATE_ENVIRONMENT_ERROR_STATUS:
     
      return {
        ...state,
        environmentDetails:{...state.environmentDetails,
          error_message:JSON.parse(payload.data.errSts).environment_id===state.environmentDetails.id?{...JSON.parse(payload.data.errSts)}:state.environmentDetails.error_message
        },
      
      }

    case FETCH_SYSTEM_VARIABLE_CONFIG_SUCCESS:
      return {
        ...state,
        settingConfig: payload.data
      }

    case FETCH_LOGGER_CONFIG_SUCCESS:
      return {
        ...state,
        loggerConfig: payload.data,
      }

    case FETCH_SCANNER_PLUGINS:
      return {
        ...state,
        fetchingScanner: true
      }
    case FETCH_SCANNER_PLUGINS_SUCCESS:
      return {
        ...state,
        fetchingScanner: false,
        scannerDetails: payload.data
      }
    case FETCH_SCANNER_PLUGINS_FAILURE:
      return {
        ...state,
        fetchingScanner: false
      }

    case FETCH_SCAN_LIST:
      return {
        ...state,
        fetchingScannerList: true
      }
    case FETCH_SCAN_LIST_SUCCESS:
      return {
        ...state,
        fetchingScannerList: false,
        scannerList: payload.data
      }
    case FETCH_SCAN_LIST_FAILURE:
      return {
        ...state,
        fetchingScannerList: false
      }

    case FETCH_SPECIFIC_REPORT:
      return {
        ...state,
        fetchingSpecificReport: true
      }
    case FETCH_SPECIFIC_REPORT_SUCCESS:
      return {
        ...state,
        fetchingSpecificReport: false,
        specificReport: payload.data
      }
    case FETCH_SPECIFIC_REPORT_FAILURE:
      return {
        ...state,
        fetchingSpecificReport: false
      }
    case TOGGLE_EXTERNAL_URL:{
      return {...state,
        toggleExternalUrlFetching:true,
        external_url:null,
        toggleExternalUrlFailure:false
      }
    }
    case TOGGLE_EXTERNAL_URL_SUCCESS:{
      return {
        ...state,
        toggleExternalUrlFetching:false,
        external_url:payload.data.external_url_updated,
        toggleExternalUrlFailure:false
      }
    }

    case TOGGLE_EXTERNAL_URL_FAILURE:{
      return {
        ...state,
        toggleExternalUrlFetching:false,
        external_url:null,
        toggleExternalUrlFailure:true
      }
    }
    case CLEAR_SPECIFIC_REPORT:
      return {
        ...state,
        specificReport:initialState.specificReport
      }

    case CLEAR_SCAN_LIST:
      return {
        ...state,
        scannerList:initialState.scannerList
      }

    case UPDATE_IP_WHITELISTING:
      return {
        ...state,
        isIPWhitelisting:true
      }

    case UPDATE_IP_WHITELISTING_SUCCESS:
    return {
      ...state,
      isIPWhitelisting:false,
      ipWhitelistingData: payload.data
    }

    case UPDATE_IP_WHITELISTING_FAILURE:
    return {
      ...state,
      isIPWhitelisting:false,
    }

    default:
      return state
  }
}

export default reducer