import React, { Component } from "react";
import {
  Typography,
  Link,
  Grid,
  Card,
  CardContent,
  IconButton,
  CardHeader,
  Collapse,
  Divider,Theme, Button, FormControlLabel, Switch
} from "@material-ui/core";
import { withStyles,WithStyles } from "@material-ui/core/styles";
import { withTranslation,WithTranslation } from "react-i18next";
import { connect,ConnectedProps } from "react-redux";
import {
  fetchEnvironmentOverViewInsights,
  fetchActivityLogs,
  fetchHpaInsight,
  clearEnvOverviewInfo,
  fetchServiceDetails
} from "../../redux/actions";
// import ChartCard from "../../../../components/chartcard/ChartCard";
import ActivityLogs from "../../../../components/activitylogs/ActivityLogs";
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  getDurationInSeconds,
  getDateInStandardFormat,
  getStatusColor
} from "../../../../helpers/utils";
import "./overviewtab.css";
import Skeleton from "react-loading-skeleton";
import LaunchIcon from '@material-ui/icons/Launch';
//import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import BackdropLoader from "../../../../components/loader/BackdropLoader";
import DomainInfoCard from "../../../../components/domainInfoCard/DomainInfoCard";
//import CustomButton from "../../../../components/custombutton/CustomButton";
import { CreateApp_TemplateTypes } from "../../../../constants/enums";
// import {KeyValueRow} from "../../../../components/keyvaluerow/KeyValueRow"
import { AppConstants } from "../../../../constants/appconstants";
// import { DateHandler } from '../../../../components/dateHandler/DateHandler';
// import CPUChartCard from "../../../../components/chartcard/CPUChartCard";
// import RAMChartCard from "../../../../components/chartcard/RAMChartCard";
import OverviewInsights from "../../../../components/environment/overview/OverviewInsights";
import AceEditor from "react-ace";
import * as yaml from "js-yaml"
import HelmServiceDetails from '../../../../components/HelmServiceDetails/HelmServiceDetails';
import { EnvSettingTabs } from "../../../../constants/environmentTabs";
import { Dispatch } from "redux";
import { HpaScalar } from "../../../../models/Environment.model";
import OverviewConfig from "../../../../components/overview/OverviewConfig";
import AlertDashBoard from "../../../../components/alertmanager/AlertDashBoard";

/* istanbul ignore next */
const useStyles = (theme:Theme) => ({
  // overviewImg: {
  //   width: 150,
  //   float: "right",
  //   [theme.breakpoints.down('xs')]: {
  //     width: 80
  //   }
  // },

  envState: {
    display: "inline",
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
});

interface Iprops extends PropsFromRedux,WithStyles,WithTranslation{
 changeHash: (hash: string, val: number, envId?: number) => void ;
 startEnv: () => void
}
interface Istate {
  //selectedPod:            string;
  selectedDuration:       string;
  selectedNameSpace:      string;
  //timer:                  ReturnType<typeof setInterval>|null;
  // dummydate:              null;
  showActivity:           boolean;
  template_vars:          string[];
  system_vars:            any;
  dnsReady:               boolean;
  showCertificateDetails: boolean;
  showAllLogs:             boolean;
}

export class OverviewTab extends Component <Iprops,Istate> {
  constructor(props:Iprops) {
    super(props);
    this.state = {
      //selectedPod: "",
      selectedDuration: "Hour",
      selectedNameSpace: "",
      // ws: props.ws ? props.ws : null,
      //timer: null,
      // dummydate: null,
      showActivity: false,
      //activityLogsFiltered: [],
      template_vars: [],
      system_vars: {},
      dnsReady: false,
      showCertificateDetails: true,
      showAllLogs: false,
    };
  }

  componentDidMount() {
    if (this.props.environmentDetails.id > 0) {
      this.getUsageData(
        {
          id: this.props.environmentDetails.id,
          duration: this.state.selectedDuration,
          horizontal_pod_autoscaler: this.props.environmentDetails?.auto_scaler?.horizontal_pod_autoscaler
        }
      );
      this.props.fetchActivityLogs(this.props.environmentDetails.id);
      if (this.props.environmentDetails.service_type === CreateApp_TemplateTypes.op)
        this.props.fetchServiceDetails(this.props.environmentDetails.id);
    }
    // if (this.props.ws && this.props.environmentState && this.props.environmentState.State !== AppConstants.EnvironmentStatus.Running)
    //   this.connectSocket(this.props.ws);

    // if (this.props.environmentState?.state === AppConstants.EnvironmentStatus.Running && this.state.timer) {
    //   clearInterval(this.state.timer)
    // }

    const cert =
      this.props.environmentState?.certificates &&
      this.props.environmentState.certificates[0];
    const certCondition =
      cert &&
      cert.status?.conditions &&
      cert.status.conditions[0];
    const certStatus = certCondition?.status === "True";
    this.setState({
      dnsReady: certStatus,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps:Iprops) {
    if (nextProps) {
      if (nextProps.environmentDetails.id > 0) {
        //if (!this.props.environmentDetails.horizontal_pod_autoscaler && nextProps.environmentDetails?.horizontal_pod_autoscaler) {
        if (!this.props.environmentDetails.id || this.props.environmentDetails.id !== nextProps.environmentDetails.id) {
          this.getUsageData(
            {
              id: nextProps.environmentDetails.id,
              duration: this.state.selectedDuration,
              horizontal_pod_autoscaler: nextProps.environmentDetails?.auto_scaler?.horizontal_pod_autoscaler
            });
          this.props.fetchActivityLogs(nextProps.environmentDetails.id);
          if (nextProps.environmentDetails.service_type === CreateApp_TemplateTypes.op)
            this.props.fetchServiceDetails(nextProps.environmentDetails.id);

          if (nextProps.environmentDetails.variables) {
            const _vars = typeof nextProps.environmentDetails.variables !== "object" ? JSON.parse(nextProps.environmentDetails.variables) : nextProps.environmentDetails.variables
            // if(_vars)
            let var_keys = Object.keys(_vars)
            let i = 0
            let _variables:string[] = []
            var_keys.map(key => {
              if (i === 2) {
                return
              }
              if (key !== 'mariadb' && !key.includes("Password") && !key.includes("password") && typeof _vars[key] !== "object") {
                console.log(_vars[key])
                if (_vars[key] && _vars[key].toString().trim().length > 0) {
                  _variables.push(key)
                  i++;
                }
              }
            })
            this.setState({
              template_vars: _variables,
              system_vars: _vars
            })
          }
        }
      }
      if (nextProps.environmentState?.state !== AppConstants.EnvironmentStatus.Running) {
        this.setState({
          showActivity: true
        })
        // if (!this.props.ws && nextProps.ws) {
        //   this.connectSocket(nextProps.ws);
        // }
      }
      // if (nextProps.environmentState?.state === AppConstants.EnvironmentStatus.Running && this.state.timer) {
      //   this.setState({
      //     showActivity: false
      //   })
      //   clearInterval(this.state.timer)

      // }
      if (nextProps.environmentState?.state) {
        if (nextProps.environmentState?.pod_status?.length > 0) {
          // const defaultPodSelected =
          //   nextProps.environmentState.ReleaseId +
          //   "-" +
          //   nextProps.environmentDetails.application.plugin.name;
          const defaultNameSpace = nextProps.environmentState.pod_status[0].namespace;
          this.setState({
            // selectedPod: defaultPodSelected,
            selectedNameSpace: defaultNameSpace,
          });
        }
      }
    }

    const cert =
      nextProps.environmentState?.certificates &&
      nextProps.environmentState.certificates[0];
    const certCondition =
      cert &&
      cert.status?.conditions &&
      cert.status?.conditions[0]
    const certStatus = certCondition?.status === "True";
    this.setState({
      dnsReady: certStatus,
    });
  }

  handleShowActivity = () => {
    this.setState({
      showActivity: !this.state.showActivity
    })
  }

  // handleDurationChange = (e) => {
  //   this.setState({
  //     selectedDuration: e.target.value,
  //   });
  //   this.getUsageData(this.props.environmentDetails.id, e.target.value, this.props.environmentDetails?.horizontal_pod_autoscaler);
  // };

  getUsageData = ({id, duration = "Hour", horizontal_pod_autoscaler}:{id:number,duration:string,horizontal_pod_autoscaler:HpaScalar}) => {  

    if(!id){
      id = this.props.environmentDetails?.id
    }
    if(!horizontal_pod_autoscaler){
      horizontal_pod_autoscaler = this.props.environmentDetails?.auto_scaler?.horizontal_pod_autoscaler
    }
    const nowInS = Math.round(new Date().getTime() / 1000);
    let payload = {
      start_time: nowInS - getDurationInSeconds(duration),
      end_time: nowInS,
    };
    this.props.fetchEnvironmentOverViewInsights(id, payload);
    if(this.props.environmentDetails?.auto_scaler?.enabled){
      this.props.fetchHpaInsight(id, payload);
    }
  };

  // updatedummystate = () => {
  //   this.setState({
  //     dummydate: Date.now(),
  //   });
  // };

  // connectSocket = (ws) => {
  //   let timer = setInterval(() => this.updatedummystate(), 500);
  //   this.setState({ timer });
  //   if (ws !== null) {
  //     ws.onmessage = (response) => {
  //       if (response.type === "message") {
  //         const { data } = response;
  //         if (data) {
  //           const _data = JSON.parse(data);
  //           if (_data.Type === "activity-logs") {
  //             if (_data.Data) {
  //               let updatedActivityLogs = [_data.Data, ...this.props.realTimeActivityLogs];
  //               this.props.updateActivityLogs(updatedActivityLogs);
  //             }
  //           }
  //         }
  //       }
  //     };
  //   }
  // };

  // handleCertificateDetail = () => {
  //   this.setState({
  //     showCertificateDetails: !this.state.showCertificateDetails
  //   })
  // }

  componentWillUnmount() {
    this.setState({
      dnsReady: false,
      showActivity: false,
      showCertificateDetails: false
    })
    this.props.clearEnvOverviewInfo()
  }

  // {
  //   "CreationTimestamp": {
  //     "Time": "2020-07-08T06:10:07Z"
  //   },
  //   "EventTime": {
  //     "Time": "2020-07-08T06:10:07.168671Z"
  //   },
  //   "FirstTimestamp": {
  //     "Time": "0001-01-01T00:00:00Z"
  //   },
  //   "Name": "zerone-metadata-589-python-798bd6fd47-c67m8.161fb21783438c45",
  //   "InvolvedObject": {
  //     "Name": "zerone-metadata-589-python-798bd6fd47-c67m8",
  //   },
  //   "LastTimestamp": {
  //     "Time": "0001-01-01T00:00:00Z"
  //   },
  //   "Message": "Successfully assigned zerone-403-589/zerone-metadata-589-python-798bd6fd47-c67m8 to do-starter-np-01-3y3ps",
  //   "Reason": "Scheduled",
  //   "Type": "Normal",
  // }

  // getActivityLogs = (activityLogs) => {
  //   if (activityLogs) {
  //     const newActivityLogs = activityLogs && activityLogs.map((log) => {
  //         return {
  //           lastSeen: getDiffDays( log.lastTimestamp ? log.lastTimestamp : log.LastTimestamp ? log.LastTimestamp.Time : "" ),
  //           Type: log.type ? log.type : log.Type,
  //           Reason: log.reason ? log.reason : log.Reason,
  //           Message: log.message ? log.message : log.Message,
  //           Object: log.involvedObject ? log.involvedObject.name : log.InvolvedObject.Name,
  //         };
  //       });
  //     this.setState({
  //       activityLogsFiltered: newActivityLogs,
  //     });
  //   }
  // };

  handleSeeVariable = () =>{
    const envTabsArr = EnvSettingTabs("#settings", this.props.environmentDetails);
    let subIndex = this.props.environmentDetails.external_secret?.enabled ?
      envTabsArr.indexOf(AppConstants.EnvSettingsTabs.externalsecret)
      : envTabsArr.indexOf(AppConstants.EnvSettingsTabs.variables);
  
    this.props.changeHash("settings", subIndex > -1 ? subIndex : 0);  
  }

  handleFilterLogs = (e: React.ChangeEvent<{ checked:boolean }>) => {
    this.setState({
      showAllLogs: e.target.checked
    })
  }

  render() {
    const {
      environmentState,
      classes,
      environmentMetadata,
      hpaInsight,
      t,
      environmentDetails,
      envServiceDetails
    } = this.props;
    // const cname =
    //   environmentState &&
    //   environmentState.State == AppConstants.EnvironmentStatus.Running &&
    //   environmentState.CName != "" &&
    //   "https://" + environmentState.CName;
    let statusColor = getStatusColor(environmentState?.state);
    //let statusTxt = getStatusText(environmentState?.State);
    
    let repoUrl = "";
    switch (this.props.environmentDetails?.service_type) { 
      case CreateApp_TemplateTypes.git: repoUrl = this.props.environmentDetails.git_url; break;
      case CreateApp_TemplateTypes.cr:
      repoUrl = this.props.environmentDetails?.application?.image_service === "dockerhub"?
      this.props.environmentDetails.image_url.replace("docker.io","https://hub.docker.com/r"):
      this.props.environmentDetails.image_url;
      break;
      default: break;
    }

  //   const data_disk_usage =
  //     this.state.selectedNameSpace &&
  //     (environmentOverviewInsights?.disk_usages ?? []).filter(
  //       (itm) => itm.metric.namespace === this.state.selectedNameSpace
  //     );
  //   const maxOfDisk =
  //     data_disk_usage && data_disk_usage.length > 0
  //       ? Math.max(...data_disk_usage[0].values.map((o) => o[1]), 0)
  //       : 0;
  //   const unitObjectDisk = formatSizeUnits(maxOfDisk, 2);

  //   // HPA Data

  //   let hpa_running = this.state.selectedNameSpace && hpaInsight?.running?.filter(itm => itm.metric.namespace === this.state.selectedNameSpace);

  //   let hpa_desired = this.state.selectedNameSpace &&  hpaInsight?.desired?.filter(itm => itm.metric.namespace === this.state.selectedNameSpace);

  //   let hpa_max_replica = this.state.selectedNameSpace &&  hpaInsight?.max_replica?.filter(itm => itm.metric.namespace === this.state.selectedNameSpace);

  //   let hpa_min_replica = this.state.selectedNameSpace &&  hpaInsight?.min_replica?.filter(itm => itm.metric.namespace === this.state.selectedNameSpace);

  //   let hpa_insight_data = []
  //   const _temp_data = [
  //     {
  //         running: hpa_running && hpa_running[0]?.values,
  //         desired: hpa_desired && hpa_desired[0]?.values,
  //         hpa_insight_max_replica: hpa_max_replica && hpa_max_replica[0]?.values,
  //         hpa_insight_min_replica: hpa_min_replica && hpa_min_replica[0]?.values,
  //     }
  // ]  
  // hpa_insight_data = convertToChartData(_temp_data, 6, { DivideBy: 1})
    // //Data Transfer chart
    // let receive_tranfer_usage =
    //   this.state.selectedPod &&
    //   environmentOverviewInsights &&
    //   environmentOverviewInsights.data_transfer &&
    //   environmentOverviewInsights.data_transfer.receive &&
    //   environmentOverviewInsights.data_transfer.receive.filter(
    //     (itm) => itm.metric.workload == this.state.selectedPod
    //   );
    // let transfer_tranfer_usage =
    //   this.state.selectedPod &&
    //   environmentOverviewInsights &&
    //   environmentOverviewInsights.data_transfer &&
    //   environmentOverviewInsights.data_transfer.transfer &&
    //   environmentOverviewInsights.data_transfer.transfer.filter(
    //     (itm) => itm.metric.pod == this.state.selectedPod
    //   );
    // let data_transfer_usage = [];
    // const maxValueOfY_Recieve =
    //   receive_tranfer_usage &&
    //   receive_tranfer_usage.length > 0 &&
    //   Math.max(...receive_tranfer_usage[0].values.map((o) => o[1]), 0);
    // const maxValueOfY_Transfer =
    //   transfer_tranfer_usage &&
    //   transfer_tranfer_usage.length > 0 &&
    //   Math.max(...transfer_tranfer_usage[0].values.map((o) => o[1]), 0);
    // const maxOfRT = Math.max(maxValueOfY_Recieve, maxValueOfY_Transfer);
    // const unitObjectRT = formatSizeUnits(maxOfRT, 2);
    // if (receive_tranfer_usage && receive_tranfer_usage.length > 0)
    //   data_transfer_usage.push({
    //     name: "receive",
    //     data: convertToChartData(
    //       receive_tranfer_usage[0].values,
    //       3,
    //       unitObjectRT
    //     ),
    //   });
    // if (transfer_tranfer_usage && transfer_tranfer_usage.length > 0)
    //   data_transfer_usage.push({
    //     name: "transfer",
    //     data: convertToChartData(
    //       transfer_tranfer_usage[0].values,
    //       3,
    //       unitObjectRT
    //     ),
    //   }); 
    const onClickHandler = () => this.props.changeHash("settings", 0)
    return (
      <div data-test="overviewContainer">
        {this.props.environmentDetails?.service_type !== undefined && this.props.environmentDetails?.service_type !== CreateApp_TemplateTypes.op &&
          <>
          {environmentState && environmentState.state ? (
            <Grid container spacing={2} data-test="overviewCardContainer">
              <Grid item md={6} xs={12}>
                <DomainInfoCard environmentState={environmentState} tab="Overview" linkClick={onClickHandler} startEnv={this.props.startEnv} envRole={this.props.envRole} />
              </Grid>
              <Grid item md={6} xs={12}>
                <Card className="overviewCard">

                  <CardContent>
                    <Grid
                      container
                      direction="column"
                      justify="center"
                    >
                      {/* <KeyValueRow rowKey={t('Projects.LoadbalancerInfo.regionLabel')} rowValue={this.props?.environmentDetails?.application?.cluster?.zone} />
                      {
                        this.props?.environmentDetails?.service_type > 0 &&
                        <>
                          {repoUrl &&
                            <KeyValueRow
                              rowKey={this.props.environmentDetails.service_type === CreateApp_TemplateTypes.git ? t('Environment.OverviewTab.git') : t('Environment.OverviewTab.cr')}
                              rowValue={(
                                <Link
                                  href={repoUrl}
                                  target="_blank"
                                  className="cnameLink"
                                  rel="noreferrer"
                                  underline="always"
                                >
                                  {repoUrl}<LaunchIcon className="openLinkIcon" />
                                </Link>)}
                            />
                          }
                          <KeyValueRow rowKey={this.props.environmentDetails.service_type === CreateApp_TemplateTypes.git ? t('Environment.OverviewTab.branch') : t('Environment.OverviewTab.tag')} rowValue={this.props.environmentDetails.service_type === CreateApp_TemplateTypes.git ? this.props.environmentDetails.git_branch : this.props.environmentDetails.image_tag} />

                        </>
                      }
                      <KeyValueRow rowKey={t('Environment.OverviewTab.lastDeployed')} rowValue={environmentMetadata?.info ? <DateHandler date={environmentMetadata.info?.last_deployed} /> : "-"} />

                      {
                        this.props?.environmentDetails?.service_type <= 0 &&
                        <>
                          {this.props?.environmentDetails?.variables && this.state.template_vars.map(key => (
                            <KeyValueRow key={key} rowKey={key} rowValue={this.state.system_vars[key]} />
                          ))}
                        </>
                      } */}
                      
                      <OverviewConfig environmentOverview={this.props.environmentOverview} state={environmentState.state} />

                      {environmentState?.state !== AppConstants.EnvironmentStatus.Stopped && (
                        <Grid item md={12} className="seeVariables">
                          <Link onClick={() => this.handleSeeVariable()}> {t('Environment.OverviewTab.seeVariables')} </Link>
                        </Grid>
                      )}
                    </Grid>
                  </CardContent>
                </Card>

              </Grid>
            </Grid>
          ) : (
            <div data-test="noStateContainer">
              <Grid>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                    data-test="noStateLeftGrid"
                  >
                    <Card className="skeletoncard">
                      <CardContent>
                        <Grid container>

                          <Grid item md={10} xs={10}>
                            <div>

                              <Typography style={{ color: statusColor }} variant="body2" className={classes.envState} >
                                <Skeleton height={20} width={"20%"} />
                              </Typography>
                            </div>

                            <Typography style={{ color: 'black' }} variant="h4">
                              <Skeleton height={20} width="50%" />
                            </Typography>
                            <Typography className="cnameLink">
                              <Skeleton height={20} width="20%" />
                            </Typography>
                          </Grid>
                          <Grid item md={2} xs={2}>
                            <Skeleton height={150} width={"100%"} />
                          </Grid>

                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item md={6} xs={12} data-test="noStateRightGrid">
                    {this.props.environmentDetails?.service_type !== undefined && this.props.environmentDetails?.service_type !== CreateApp_TemplateTypes.op &&
                      <Card className="skeletoncard">
                        <CardContent>
                          <Grid container spacing={2}>
                            {[1, 2, 3, 4].map(ind => (
                              <React.Fragment key={ind}>
                                <Grid item md={4}>
                                  <Typography variant="body2" >
                                    <Skeleton height={20} width={"100%"} />
                                  </Typography>
                                </Grid>
                                <Grid item md={8}>
                                  <Skeleton height={20} width={"100%"} />
                                </Grid>

                              </React.Fragment>
                            ))}

                          </Grid>

                        </CardContent>
                      </Card>
                    }
                  </Grid>
                </Grid>
              </Grid>
            </div>
          )}
          </>
        }
        {this.props.environmentDetails?.service_type === CreateApp_TemplateTypes.op && environmentState?.state === AppConstants.EnvironmentStatus.Running &&
          <Grid container spacing={2} data-test="overviewCardContainer">
            <Grid item md={12} xs={12}>
              <HelmServiceDetails serviceDetails={envServiceDetails} />
            </Grid>
          </Grid>
        }
            {this.state.dnsReady &&
          environmentState?.certificates &&
          environmentState.certificates[0] && (
          <div className="m-t-20" data-test="variableInfoContainer">
              <Card>
                  <CardContent>
                      <Grid container spacing={3} className="m-b-10" alignItems="center">
                          <Grid item md={12}>
                              <Grid container spacing={2} alignItems="center">
                                  <Grid item>
                                      <LockOutlinedIcon fontSize="small" className="greenIcon" />
                                  </Grid>
                                  <Grid item>
                                      <Typography variant="h5" className="m-b-10">{t('Environment.OverviewTab.customDomain')}</Typography>
                                  </Grid>
                              </Grid>
                              <Divider />
                          </Grid>
                      </Grid>
                      <Grid>
                          <Grid container>
                              <Grid item xs={12} md={12} >
                                  <Typography gutterBottom variant="h5" >
                                  {t('Environment.OverviewTab.letsEncrypt')}
                                  </Typography>
                              </Grid>
                              {/* <Grid item xs = {12} md = {12} >
                      <Typography gutterBottom variant="body2" >
                        <Link onClick={this.handleCertificateDetail}> {this.state.showCertificateDetails ? "Show Less" : "Show More"} </Link>
                      </Typography>
                    </Grid> */}
                          </Grid>
                          <Collapse in={this.state.showCertificateDetails && this.state.dnsReady} timeout={600} unmountOnExit>
                              <Grid container>
                                  <Grid item xs={6} md={2}>
                                      <Typography color="primary" variant="h6">  {t('Environment.OverviewTab.certificate')} </Typography>
                                  </Grid>
                                  <Grid item xs={6} md={10}>
                                    {"Let's Encrypt"}
                                  </Grid>
                              </Grid>
                              <Grid container>
                                  <Grid item xs={6} md={2}>
                                      <Typography color="primary" variant="h6">{t('Environment.OverviewTab.domain')}</Typography>
                                  </Grid>
                                  <Grid item xs={6} md={10}>
                                    <Link href={`//${environmentState?.certificates[0]?.spec?.commonName}`} target="_blank" className="cnameLink">
                                      {environmentState.certificates[0].spec &&
                                        environmentState.certificates[0].spec
                                            .commonName}<LaunchIcon className="openLinkIcon" />
                                    </Link>
                                  </Grid>
                              </Grid>
                              <Grid container>
                                  <Grid item xs={6} md={2}>
                                      <Typography color="primary" variant="h6">{t('Environment.OverviewTab.created')}</Typography>
                                  </Grid>
                                  <Grid item xs={6} md={10}>
                        {environmentState.certificates[0].metadata &&
                            getDateInStandardFormat(
                              environmentState.certificates[0].metadata
                                .creationTimestamp
                            )}
                                  </Grid>
                              </Grid>
                    {environmentState.certificates[0].status &&
                      environmentState.certificates[0].status
                          .notAfter && (
                          <Grid container>
                              <Grid item xs={6} md={2}>
                                  <Typography color="primary" variant="h6">{t('Common.expiryDate')} </Typography>
                              </Grid>
                              <Grid item xs={6} md={10}>
                                  {getDateInStandardFormat(
                                    environmentState.certificates[0].status
                                  .notAfter
                              )}
                              </Grid>
                          </Grid>
                        )}
                          </Collapse>
                      </Grid>

                  </CardContent>
              </Card>
          </div>
          )}

            <div className="m-t-20" data-test="activityContainer">
                <Card>
                    <CardHeader
                      title={"Activities"}
                      // onClick={() => this.handleShowActivity()}
                      action={
                        <>
                        {
                          this.state.showActivity && 
                          <FormControlLabel
                          control={
                              <Switch
                                name="push"
                                color="primary"
                                checked={this.state.showAllLogs}
                                onChange={e => this.handleFilterLogs(e)}
                              />
                            }
                          label="Show All Logs"
                        />
                        }

                        <IconButton
                          onClick={() => this.handleShowActivity()}
                          aria-label="Show/Hide Activity"
                          aria-expanded={this.state.showActivity}
                          className={clsx(classes.expand, {
                          [classes.expandOpen]: this.state.showActivity,
                          })}
                        >
                            {/* { this.state.showActivity ? <ExpandLessIcon /> :<ExpandMoreIcon />} */}
                            <ExpandMoreIcon />
                        </IconButton>
                        </>
              }
                    >
                    </CardHeader>
                    <Collapse in={this.state.showActivity} timeout={600} unmountOnExit>
                        <CardContent>
                            {/* {this.state.showActivity &&
                  } */}
                            {/* ((this.props.activityLogs && this.props.activityLogs.length> 0) || (this.props.realTimeActivityLogs && this.props.realTimeActivityLogs.length > 0)) && ( */}
                            <div>
                                <ActivityLogs activityLogs={this.props.activityLogs} realTimeActivityLogs={this.props.realTimeActivityLogs} showAllLogs = {this.state.showAllLogs}/>
                            </div>
                        </CardContent>
                    </Collapse>
                </Card>

                <AlertDashBoard changeHash={this.props.changeHash}/>

                {environmentDetails.service_type === CreateApp_TemplateTypes.op &&
                  environmentDetails.operator_payload && (
                    <Card className="m-t-20">
                      <CardHeader title="Installation Values"></CardHeader>
                      <CardContent>
                        <AceEditor
                          data-test="editor-container"
                          mode="yaml"
                          theme={"xcode"}
                          width="100%"
                          readOnly={true}
                          setOptions={{ showPrintMargin: false }}
                          editorProps={{ $blockScrolling: Infinity }}
                          value={yaml.dump(environmentDetails.operator_payload)}
                          className="editor"
                          fontSize="15px"
                        />
                      </CardContent>
                    </Card>
                  )}

          {/* {this.props.environmentState?.ready && this.props.environmentState?.state !== AppConstants.EnvironmentStatus.Stopped && ( */}
                  <OverviewInsights
                    environmentDetails = {this.props.environmentDetails}
                    // environmentState = {this.props.environmentState}
                    environmentOverviewInsights = {this.props.environmentOverviewInsights}
                    getUsageData = {this.getUsageData}
                    selectedNameSpace = { this.state.selectedNameSpace }
                    hpaInsight={hpaInsight}
                  />
          {/* )} */}
            </div>
            {this.props.fetchingEnvOverviewInsight && <BackdropLoader message={t('Environment.OverviewTab.fetchingInsights')} />}
      </div>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state:any) => ({
  environmentOverviewInsights:
    state.EnvironmentReducer.environmentOverviewInsights,
  environmentState: state.EnvironmentReducer.environmentState,
  environmentDetails: state.EnvironmentReducer.environmentDetails,
  environmentMetadata: state.EnvironmentReducer.environmentMetadata,
  environmentOverview: state.EnvironmentReducer.environmentOverview,
  activityLogs: state.EnvironmentReducer.activityLogs,
  realTimeActivityLogs: state.EnvironmentReducer.realTimeActivityLogs,
  hpaInsight: state.EnvironmentReducer.hpaInsight,
  fetchingEnvOverviewInsight: state.EnvironmentReducer.fetchingEnvOverviewInsight,
  envServiceDetails: state.EnvironmentReducer.envServiceDetails,
  envRole: state.EnvironmentReducer.envRole
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch:Dispatch) => {
  return {
    //fetchEnvironmentStateInitiate: (id) => dispatch(fetchEnvironmentStateInitiate(id)),
    fetchEnvironmentOverViewInsights: (id:number, payload:{
      start_time: number;
      end_time: number;
  }) => dispatch(fetchEnvironmentOverViewInsights(id, payload)),
    // updateActivityLogs: (data) => dispatch(updateActivityLogs(data)),
    fetchActivityLogs: (id:number) => dispatch(fetchActivityLogs(id)),
    clearEnvOverviewInfo: () => dispatch(clearEnvOverviewInfo()),
    fetchHpaInsight: (id:number, payload:{ start_time: number;end_time: number;
  }) => dispatch(fetchHpaInsight(id, payload)),
    fetchServiceDetails: (id:number) => dispatch(fetchServiceDetails(id)),
  };
};

const connector=connect(
  mapStateToProps,
  mapDispatchtoProps
)
export default connector(withStyles(useStyles)(withTranslation()(OverviewTab)));

type PropsFromRedux= ConnectedProps<typeof connector>
