/* eslint-disable no-undef */
export default {
    AUTH: {
        LOGIN: window?.config?.REACT_APP_RESTAPI_ENDPOINT +'/user/login',
        LOGOUT:window?.config?.REACT_APP_RESTAPI_ENDPOINT +'/user/logout',
        REGISTER: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/user/register',
        FORGOT_PASSWORD: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/user/forgot-password',
        UPDATE_PASSWORD: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/user/change-password',
        LOGIN_OAUTH: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/user/login/external',
        VERIFY_EMAIL: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/user/verify/:token',
        RESEND_VERIFY_EMAIL: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/user/resend-verification',
        DEACTIVATE_ACCOUNT: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/user/account/deactivate',
        REQUEST_INVITE: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/user-invite-request',
        GET_INVITE_DETAILS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/user-invite-register/:token',
        REQUEST_INVITE_SIGNUP: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/user-invite-register/:token',
        LOGIN_SSO: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/user/login/sso',
    },
    GET_ACCESS_TOKEN_LIST: window?.config?.REACT_APP_RESTAPI_ENDPOINT + "/user/tokens",
    CREATE_ACCESS_TOKEN: window?.config?.REACT_APP_RESTAPI_ENDPOINT + "/user/token",
    DELELE_ACCESS_TOKEN: window?.config?.REACT_APP_RESTAPI_ENDPOINT + "/user/token/:id",
    DELETE_ALL_ACCESS_TOKEN: window?.config?.REACT_APP_RESTAPI_ENDPOINT + "/user/tokens",
    GET_ACCOUNT_LIST: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/external/connections',
    UNLINK_ACCOUNT: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/external/revoke/:id',
    GET_PROFILE: window?.config?.REACT_APP_RESTAPI_ENDPOINT +'/user/:userId',
    PLUGIN_LIST: window?.config?.REACT_APP_RESTAPI_ENDPOINT +'/plugins?support_ci=:support_ci&size=:size&page=:page&search=:search',
    PLUGIN_DETAILS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/plugin/:pId',
    PLUGIN_VERSION: window?.config?.REACT_APP_RESTAPI_ENDPOINT +'/plugin/:pId/latest-version',
    CREATE_PROJECT : window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/project',
    GET_PROJECT_LIST : window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/projects',
    GET_SUBSCRIPTION_LIST : window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/subscriptions',
    GET_SUBSCRIPTION : window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/subscription/:id',
    CREATE_SUBSCRIPTION: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/subscription',
    EDIT_SUBSCRIPTION: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/subscription/:id',
    UPDATE_PROJECT_DETAILS : window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/project/:projectId',
    DELETE_PROJECT : window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/project/:projectId',
    REGIONS : window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/regions',
    REGION: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/get-regions',
    REGIONCLUSTERS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/get-clusters?region=:region',
    PROJECT_DETAILS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/project/:pId',
    GET_APP_LIST: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/project/:pId/applications?search=:query&plugin_id=:plugin&region=:cluster',
    GET_APP_DETAILS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/application/:aid',
    DELETE_APP: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/application/:aid',
    GET_ENVIRONMENT_LIST: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/application/:aid/environments',
    GET_HELMENVIRONMENT_LIST: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/application/:aid/helm-environments',
    UPDATE_USER_DETAILS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/user/:userId',
    ENV_DETAILS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId',
    START_ENVIRONMENT: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/restart',
    STOP_ENVIRONMENT: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/stop',
    DELETE_ENVIRONMENT: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId',
    RESET_PASSWORD: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/user/reset-password',
    CREATE_APPLICATION: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/application',
    CREATE_ENVIRONMENT: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment',
    DEPLOY_APPLICATION: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/application/:appId/deploy',
    ADD_USERS_IN_PROJECT: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/project/:projectId/user',
    EDIT_USER_IN_PROJECT: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/project/:projectId/user/:userId',
    LIST_USERS_IN_PROJECT: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/project/:projectId/users',
    DELETE_USERS_IN_PROJECT: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/project/:projectId/user/:userId',
    FETCH_ROLES_DATA: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/roles',
    ADD_USERS_IN_ENVIRONMENT: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:environmentId/user',
    EDIT_USER_IN_ENV: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:environmentId/user/:userId',
    LIST_USERS_IN_ENVIRONMENT: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:environmentId/users',
    DELETE_USERS_IN_ENVIRONMENT: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:environmentId/user/:userId',
    GET_ENVIRONMENT_IN_CLUSTER: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/cluster/:id/environments',
    //DEPLOY_APPLICATION: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/application/:appId/deploy',
    RENAME_APP: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/rename/application/:id',
    UPDATE_ENV_NAME: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/rename/environment/:id',
    SYSTEM_VARIABLE: window?.config?.REACT_APP_RESTAPI_ENDPOINT+'/plugin-version/:id/settings',
    RESOURCES: {
        FETCH_RESOURCES: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/resources',
        CREATE_RESOURCE: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/resource',
        EDIT_RESOURCE: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/resource/:id',
        GET_RESOURCE: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/resource/:id'
    },
    ENVIRONMENT: {
        GET_VARIABLES: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/variables',
        UPDATE_VARIABLES: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/variables',
        START_FETCHING_LOGS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/fetch-logs?no_of_lines=:lines',
        GET_LOGS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/status',
        UPDATE_ENV: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId',
        GET_INSIGHTS : window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/insights',
        GET_OVERVIEW_INSIGHTS : window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/overview',
        GET_STATE_INITIATE: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/fetch-state',
        GET_STATE: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/state',
        GET_ENV_WORKFLOWS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/workflow?page=:page&limit=:limit',
        GET_ENV_WORKFLOW_LOG:  window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/workflow-log?workflow_name=:workflow-name',
        GET_ENV_CI_LOG: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/:workflow-name/:task/:step',        
        RERUN_ENV_CI_WORKFLOW: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/rerun-ci',
        STOP_ENV_CI_WORKFLOW: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/stop-ci?workflow_name=:workflow-name',
        GET_CICD_SETTINGS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/ci-trigger',
        UPDATE_CICD_SETTINGS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/ci-trigger',
        GET_CD_SETTINGS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/cd-config',
        UPDATE_CD_SETTINGS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/cd-config',
        GET_ACTIVITY_LOGS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/activity-log',
        RECREATE_ENV : window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/re-deploy',
        INSTALL_ADDON: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/addons',
        MODIFY_ADDON: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/addons/:aId',
        UNINSTALL_ADDON: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/addons/:aId',
        GET_ADDONS_STATUS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/addons-status',
        GET_HPA_INSIGHT: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/hpa-insight',
        GET_CI_STATS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/ci-metrics',
        GET_PODS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/pods',
        START: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/start',
        STOP: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/stop',
        GET_INSTALLED_ADDONS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/addons',
        GET_ADDON_INFO: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/addons/:aId',
        TEST_EMAIL: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/test/email',
        TEST_SLACK: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/test/slack',
        TEST_WEBHOOK: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/test/webhook',
        ROLLBACK: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/rollback',
        FETCH_BUILD_IMAGE: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/build-images',
        FETCH_STORAGE: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/storage-fetch',
        GET_STORAGE: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/storage',
        CREATE_STORAGE: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/storage',
        EDIT_STORAGE: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/storage/:sId',
        DELETE_STORAGE: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/storage/:sId',
        FETCH_PACKAGE_STATUS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/package-status',
        GET_DEPLOYMENT_TYPES: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/cd-config',
        FETCH_CD_LIST: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:id/revision-list',
        FETCH_CD_STATUS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:id/revision-fetch',
        UPDATE_CUSTOM_DOMAIN: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/dns',
        OPERATOR_SERVICE_DETAILS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/operator-service',
        UPDATE_ENV_SCHEDULE: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/schedule',
        FETCH_SCHEDULE_LOGS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/schedule/logs',
        UPDATE_FILE_MANAGER_STATUS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/enable-disable-filemanager?status=:status',
        UPDATE_GIT_BRANCH: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/change-branch',
        UPDATE_CR_TAG: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/change-tag',
        CLONE_ENVIRONMENT: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/clone',
        UPDATE_SECRETS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/external-secret',
        UPDATE_SECRETS_SYNC: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/external-secret-sync',
        FETCH_SECRETS_LOGS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/external-secret-log',
        UPDATE_LOGGER: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/external-logging',
        LOGGER_CONFIG: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/public/file/external-logger.json',
        GET_ALERTS_LISTS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/monitoring/templates/:eId',
        GET_ALERTS_RULES: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/monitoring/templates/:eId/:template',
        ADD_RULES: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/monitoring/templates/:eId/:template',
        INSTALL_ALERT_TEMPLATE: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/monitoring/templates/:eId/:template',
        NOTIFI_ALERT_DETAILS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/monitoring/alerts/:eId',
        GET_ALERTS_DASHBOARD_STATUS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/monitoring/alert-status/:eId?state_type=:state_name',
        ALERT_HISTORY: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/monitoring/alert-history/:id?page=:page&limit=:limit',
        SCANNER_PLUGINS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/scanner/:eId/plugins',
        START_SCANNER: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/scanner/:eId/scan',
        SCANNER_LISTS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/scanner/:eId/reports',
        SPECIFIC_RERPORT: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/scanner/:eId/reports/:reportId',
        UPDATE_EXTERNAL_URL: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/addons/:aid/external-url',
        UPDATE_IP_WHITELISTING: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:id/ip-whitelist',
    },
    CRONJOB: {
        CREATE_CRONJOB: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/cronjob',
        UPDATE_CRONJOB: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/cronjob/:cId',
        DELETE_CRONJOB: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/cronjob/:cId',
        GET_CRONJOB_LIST: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/cronjob',
        GET_CRONJOB_DETAILS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/cronjob/:cId',
        CRONJOB_FETCH: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/cronjob-fetch',
        GET_CRONJOB_STATUS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/cronjob-status',
        GET_CRON_IMAGES: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/cron-images',
        RUN_CRONJOB: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/cronjob/:cId/run',
        GET_CRONJOB_LOG: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/cronjob/:cId/logs?page=:page&limit=:limit'
    },
    INIT_CONTAINER:{
        CREATE_INIT_CONTAINER: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/init-container',
        FETCH_INIT_CONTAINERS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/init-container',
        UPDATE_INIT_CONTAINER: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/init-container/:cId',
        FETCH_INIT_CONTAINER: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/init-container/:cId',
        DELETE_INIT_CONTAINER: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/init-container/:cId',

    },
    PLUGIN: {
        GET_VERSION_CONFIG: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/plugin-version/:id/config',
        GET_ADDONS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/plugin/:id/add-ons?cat_id=:cat_id&query=:query',
        GET_ADDON_CATEGORIES: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/plugin-category?is_add_on=true',
        FETCH_PLUGINS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/plugins',
        CREATE_PLUGIN: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/plugin',
        EDIT_PLUGIN: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/plugin/:id',
        GET_PLUGIN: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/plugin/:id',
        UPLOAD_ICON:  window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/upload',
        FETCH_ALL_PLUGINS:  window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/plugins/active',
    },
    PROJECT: {
        GET_ACTIVITIES : window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/project/:pId/activities',
        GET_INSIGHTS : window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/project/:pId/insights',
        UPLOAD_ICON : window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/upload',
        GET_SIDEBAR_INFO: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/sidebar',
        GET_USED_RESOURCES: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/project/:pId/resource',
        GET_LOADBALANCERS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/project/:pId/loadbalancers',
        DEACTIVATE_PROJECT: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/project/:pId/activation',

    },
    LOADBALANCER: {
        DELETE_LOADBALANCER: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/loadbalancer/:lId',
        CREATE_LOADBALANCER: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/loadbalancer',
        GET_LOADBALANCER: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/loadbalancer/:lId',
        FETCH_STATUS_CALL: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/loadbalancer/:lId/fetch-status',
        FETCH_STATUS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/loadbalancer/:lId/status',
    },
    GET_ROLE : window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/role/:module/:id',
    SEARCH: {
        GET_RESULT : window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/search?query=:query',
    },
    APP: {
        GET_AVAILABLE_RESOURCE: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/application/:aId/available-resource' 
    },
    GITHUB: {
       GIT_CONNECT : window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/git/connect',
       GET_ORGANIZATIONS : window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/git/org/:service',
       GET_ORG_REPOS : window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/git/repo/:service?org=:org_name',
       GET_PR_REPOS : window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/git/repo/:service',
       GET_BRANCHES : window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/git/branch/:service?repo=:repo_id&uid=:uid'
    },
    CONTAINERREGISTRY: {
        GET_ORGANIZATIONS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/registry/org/:service?namespace=default',
        CR_CONNECT: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/registry/connect',
        GET_PR_REPOS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/registry/repos/:service?namespace=personal',
        GET_ORG_REPOS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/registry/repos/:service?namespace=:org_name',
        GET_TAGS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/registry/repo/tags/:service?namespace=:org_name&repo=:repo_name'
    },
    ORGANIZATION: {
        GET_PLANS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/organizationPlans',
        CREATE_ORG: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/organization',
        GET_LIST: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/organizations',
        SWITCH_ORG: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/organization/:id/switch',
        GET_INFO: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/organization',
        DELETE: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/organization',
        ADD_MEMBER: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/organization/members',
        EDIT_MEMBER: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/organization/members',
        DELETE_MEMBER: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/organization/members',
        ADD_PLUGIN: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/organization/plugin/:pId',
        ADD_PLUGINS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/organization/add-plugins',
        REMOVE_PLUGIN: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/organization/plugin/:pId',
        FETCH_SUBSCRIPTIONS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/admin/subscriptions',
        FETCH_RESOURCES: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/admin/resources',
        CREATE_DNS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/dns',
        GET_DNS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/dns',
        DELETE_DNS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/dns/:dns_id',
        GET_DNS_BY_ID: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/dns/:dns_id',
        UPDATE_DNS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/dns/:dns_id',
        GET_ACTIVITIES : window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/organization/:orgId/activities',
    },
    CHART: {
        SYNC_CHART_REPO: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/helm/repos/:id/sync',
        GET_CHART_REPOS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/helm/repos',
        ADD_CHART_REPO: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/helm/repos',
        DELETE_CHART_REPO: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/helm/repos/:id',
        UPDATE_CHART_REPO: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/helm/repos/:id',
        GET_CHART_CATALOGS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/helm/charts?page=:page&query=:search&category=:category&repo_id=:repository',
        GET_CATALOG_CATEGORY: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/helm/chart/categories',
        GET_CHART_DETAIL: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/helm/chart/:id',
        GET_CHART_VERSIONS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/helm/chart-version/:id',
        GET_CHART_VERSION_CONFIG: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/helm/chart-version/:id/config',
        GET_CHART_VERSION_VALUES: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/helm/chart-version/:id/values.yaml',
        GET_CHART_VERSION_SCHEMAS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/helm/chart-version/:id/schema.json',
    },
    HELMENVIRONMENT: {
        CREATE_HELM_ENVIRONMENT: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/helm-environment',
        DELETE_HELM_ENVIRONMENT: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/helm-environment/:id',
        GET_HELM_ENVIRONMENT_INFO: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/helm-environment/:id',
        UPDATE_HELM_ENVIRONMENT: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/helm-environment/:id',
        GET_STATE: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/helm-environment/:eId/state',
        GET_STATE_INITIATE: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/helm-environment/:eId/fetch-state',
        GET_HELM_ACTIVITY_LOGS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/helm-environment/:id/activity-log',
        GET_HELM_LOGS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/helm-environment/:id/status',
        GET_HELM_LOGS_INITIATE: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/helm-environment/:id/fetch-logs?no_of_lines=:duration',
        GET_HELM_PODS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/helm-environment/:id/pods',
        LAUNCH_HELM_ENV: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/helm-environment/:id/launch',
        START_HELM_ENV: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/helm-environment/:id/start',
        STOP_HELM_ENV: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/helm-environment/:id/stop',
        REDEPLOY_HELM_ENV: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/helm-environment/:id/re-deploy',
        GET_HELM_ENV_INSIGHT: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/helm-environment/:id/insights',
        GET_HELM_ENV_OVERVIEW: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/helm-environment/:id/overview',
        GET_USERS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/helm-environment/:id/users',
        ADD_USER: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/helm-environment/:id/users',
        EDIT_USER: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/helm-environment/:id/users/:userId',
        DELETE_USER: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/helm-environment/:id/users/:userId',
        GET_BACKUP_LIST: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/helm-environment/:eId/backup',
        CREATE_BACKUP: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/helm-environment/:eId/backup',
        DELETE_BACKUP: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/helm-environment/:eId/backup/:bId',
        PRESERVE_BACKUP: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/helm-environment/:eId/backup/:bId/preserve',
        RESTORE_BACKUP: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/helm-environment/:eId/backup/:bId/restore',
        GET_BACKUP_SETTINGS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/helm-environment/:eId/backup/setting',
        UPDATE_BACKUP_SETTINGS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/helm-environment/:eId/backup/setting',
        GET_BACKUP_RESTORE_LIST: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/helm-environment/:eId/restores',
        UPDATE_ENV_SCHEDULE: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/helm-environment/:eId/schedule',
        FETCH_SCHEDULE_LOGS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/helm-environment/:eId/schedule/logs'
    },
    GROUP: {
        GET_GROUPS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/groups',
        ADD_GROUP: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/groups',
        GET_INFO: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/groups/:gId',
        DELETE_GROUP: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/groups/:gId',
        UPDATE_GROUP: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/groups/:gId',
        ADD_MEMBER: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/groups/:gId/members',
        DELETE_MEMBER: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/groups/:gId/members',
    },
    CLUSTER: {
        CREATE_CLUSTER: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/create-cluster',
        UPDATE_CLUSTER: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/create-cluster/:id',
        GET_CLUSTER_LIST: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/create-cluster',
        GET_CLUSTER: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/create-cluster/:id',
        APPLY_TERRAFORM: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/create-cluster/:id/apply',
        GET_WORKFLOWS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/create-cluster/:id/workflows?page=:page&limit=:limit',
        CANCEL_WORKFLOW: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/create-cluster/:id/cancel?workflow_name=:workflow',
        GET_WORKFLOW_LOG: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/create-cluster/:id/workflow-log?workflow_name=:workflow-name',
        GET_CLUSTER_LOG: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/cluster/:id/:workflow-name/:task/:step',        
        DOWNLOAD_TF_FILES: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/create-cluster/:id/download-files',
        DELETE_CLUSTER: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/create-cluster/:id',
        DESTROY_CLUSTER: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/create-cluster/:id/destroy',
        CHECK_PERMISSION: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/check-permissions',
        GET_PROVIDER_CONFIG: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/create-cluster-config?provider=:provider',
        IMPORT_CLUSTER: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/import-cluster',
        GET_CLUSTER_PACKAGE: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/package-config',
        INSTALL_CLUSTER_PACKAGE: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/create-cluster/:id/install-package',
        UNINSTALL_CLUSTER_PACKAGE: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/create-cluster/:id/uninstall-package',
        GET_PACKAGE_STATUS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/create-cluster/:id/package-status',
        ENABLE_DISABLE_CLUSTER: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/create-cluster/:id/change-active-status',
        UPDATE_CLUSTER_REPO: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/cluster/:id',
        SETUP_CLUSTER_DNS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/cluster/:id/add-dns',
        CHECK_DNS_PERMISSION: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/check-dns-permissions',
        CREATE_STORAGE: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/cluster/:cluster_id/change-storage',
        UPDATE_STORAGE: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/cluster/:cluster_id/change-storage',
        GET_CLUSTER_INSIGHTS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/create-cluster/:cluster_id/insights',
        UPDATE_CLUSTER_COLOR_AND_LABELS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/create-cluster/:id/label-color',
        GET_PROVIDER_PERMISSIONS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/get-permissions?provider=:provider',
        CREATE_V_CLUSTER:   window?.config?.REACT_APP_RESTAPI_ENDPOINT + "/create-cluster/vcluster",
        VALIDATE_V_CLUSTER_TOKEN:   window?.config?.REACT_APP_RESTAPI_ENDPOINT + "/create-cluster/vcluster/validate",
        CLUSTER_SCANNER_PLUGINS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + "/scanner/cluster-plugins",
        CLUSTER_SCANNER_SCAN: window?.config?.REACT_APP_RESTAPI_ENDPOINT + "/scanner/:id/cluster-scan",
        GET_CLUSTER_SCAN_REPOART: window?.config?.REACT_APP_RESTAPI_ENDPOINT + "/scanner/:id/cluster-reports",
        GET_SPECIFIC_CLUSTER_RERPORT: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/scanner/:cId/reports/:reportId'
    },
    NOTIFICATIONS: {
        GET_NOTIFICATION_LIST : window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/notifications?page=:page&limit=:limit&filter=:filter',
        MARK_AS_READ_NOTIFICATION: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/notification/:id',
        UPDATE_SEEN_UNSEEN: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/notifications/seen-unseen',
        GET_UNSEEN_COUNT: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/notification/get-unseen-count',
        MARK_ALL_AS_READ: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/notification/mark-all-as-read',
    },
    SUPPORT: {
        GET_USER_TICKETS : window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/ticket',
        CREATE_SUPPORT_TICKET : window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/ticket',
        ASSIGN_TICKET : window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/ticket/assign',
        GET_TICKET_GROUP : window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/ticket/dashboard',
        CREATE_TICKET_GROUP : window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/ticket/group',
        CREATE_ISSUE_TYPE : window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/ticket/issue',
        REPLY_ISSUE : window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/ticket/reply',
        GET_TICKET_DETAIL : window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/ticket/details/:id',
        DELETE_TICKET : window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/ticket/:id',
        UPDATE_SUPPORT_TICKET : window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/ticket/:id',
        GET_SUPPORT_TYPES : window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/ticket/group',
        GET_ISSUE_TYPES : window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/ticket/issue-types',
    },
    BACKUP: {
        GET_BACKUP_LIST: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/backup',
        CREATE_BACKUP: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/backup',
        DELETE_BACKUP: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/backup/:bId',
        PRESERVE_BACKUP: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/backup/:bId/preserve',
        RESTORE_BACKUP: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/backup/:bId/restore',
        GET_BACKUP_SETTINGS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/backup/setting',
        UPDATE_BACKUP_SETTINGS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/backup/setting',
        GET_BACKUP_RESTORE_LIST: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/environment/:eId/restores',
    },
    AUTOCOMPLETE_SEARCH: window?.config?.REACT_APP_RESTAPI_ENDPOINT + "/search/user?query=:query",
    
    REGISTRY: {
        GET_REGISTRY_LIST: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/registries',
        GET_REGISTRY: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/registry/:id',
        ADD_REGISTRY: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/registry',
        UPDATE_REGISTRY: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/registry/:id',
        DELETE_REGISTRY: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/registry/:id',
        GET_REGISTRY_CONFIG: window?.config?.REACT_APP_RESTAPI_ENDPOINT + '/registry-config'
    },
    GCS_UPLOAD:  window?.config?.REACT_APP_RESTAPI_ENDPOINT + "/upload-gcs",
    PAYMENT: {
        GET_INVOICE: window?.config?.REACT_APP_RESTAPI_ENDPOINT + "/payment/invoice",
        GET_INVOICE_BY_ID: window?.config?.REACT_APP_RESTAPI_ENDPOINT + "/payment/invoice/:id",
        GET_PAYMENT_SETTING: window?.config?.REACT_APP_RESTAPI_ENDPOINT + "/payment/paymentsetting",
        UPDATE_PAYMENT_SETTING: window?.config?.REACT_APP_RESTAPI_ENDPOINT + "/payment/paymentsetting",
        GET_PAYMENT_THRESHOLD: window?.config?.REACT_APP_RESTAPI_ENDPOINT + "/payment/threshold",
        UPDATE_PAYMENT_THRESHOLD: window?.config?.REACT_APP_RESTAPI_ENDPOINT + "/payment/threshold",
        GET_DEDUCTION_BY_ID: window?.config?.REACT_APP_RESTAPI_ENDPOINT + "/payment/deduction/:id",
        GET_PAYMENT_HISTORY: window?.config?.REACT_APP_RESTAPI_ENDPOINT + "/payment/paymenthistory?size=:size&page=:page",
        INVOICE_DOWNLOAD: window?.config?.REACT_APP_RESTAPI_ENDPOINT + "/payment/invoice/:id/download?token=:token",
        GET_GATEWAY_DATA: window?.config?.REACT_APP_RESTAPI_ENDPOINT + "/payment/gateway",
        GET_INIT_DATA: window?.config?.REACT_APP_RESTAPI_ENDPOINT + "/payment/init/:code",
        GET_INIT_DATA_BY_ID: window?.config?.REACT_APP_RESTAPI_ENDPOINT + "/payment/init/:code?amount=:amount",
    },
    OPERATOR: {
        GET_AVAILABLE_OPERATORS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + "/operators",
        GET_OPERATOR_DETAIL: window?.config?.REACT_APP_RESTAPI_ENDPOINT + "/operator?packageName=:packageName",
        INSTALL_OPERATOR: window?.config?.REACT_APP_RESTAPI_ENDPOINT + "/cluster/:id/operator/install",
        REINSTALL_OPERATOR: window?.config?.REACT_APP_RESTAPI_ENDPOINT + "/cluster/:clusterId/operator/:operatorId/reinstall",
        UNINSTALL_OPERATOR: window?.config?.REACT_APP_RESTAPI_ENDPOINT + "/cluster/:clusterId/operator/:operatorId",
        FETCH_INSTALLED_OPERATORS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + "/cluster/:id/operators",
        FETCH_OPERATOR_STATUS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + "/cluster/:id/operator/status",
        FETCH_OPERATOR_LOGS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + "/cluster/:clusterId/operator/:operatorId/activity-log"
    },

    PODS: {
      GET_PODS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + "/monitoring/podlist/:envId",
      UPDATE_POD_LABEL: window?.config?.REACT_APP_RESTAPI_ENDPOINT + "/monitoring/podlabels/:envId/:podName",
      GET_CONTAINERS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + "/monitoring/containerlist/:envId?name=:podName",
      GET_CONTAINER_INSIGHTS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + "/monitoring/containermonitor/:envId?name=:podName",
      GET_LOGS_WEBSHELL_TOKEN: window?.config?.REACT_APP_RESTAPI_ENDPOINT + "/monitoring/terminal",
      FETCH_POD_EVENTS: window?.config?.REACT_APP_RESTAPI_ENDPOINT + "/monitoring/events/:envId",
      FETCH_INSIGHT_GRAPH_DATA: window?.config?.REACT_APP_RESTAPI_ENDPOINT + "/monitoring/insights/:envId",
      RESTART_POD: window?.config?.REACT_APP_RESTAPI_ENDPOINT + "/monitoring/restart/:envId",
    }
}