import React, { Component } from "react";
import "./variablestab.css";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  fetchEnvVariables,
  updateEnvVariables,
  fetchPlugInVersionConfig,
  clearPluginVersionConfig,
  getSettingVariableConfig,
} from "../../../redux/actions";
import "./variablestab.css";
import UserVariablesTable from "../../../../../components/uservariablestable/UserVariablesTable";
import ConfirmActionPopup from "../../../../../components/confirmactionpopup/ConfirmActionPopup";
import BackdropLoader from "../../../../../components/loader/BackdropLoader";
import { flatten } from "../../../../../helpers/utils";
import _ from "lodash";
import { fetchProjectDetailForEnv } from "../../../../project/redux/actions";
import SystemVariable from "./SystemVariable";

/* istanbul ignore next */
const useStyles = () => ({
  actions: {
    display: "flex",
  },

  save: {
    marginLeft: "auto",
  },
  header: {
    margin: "30px 50px 50px 0px",
  },
  topmargin: {
    marginTop: 15,
  },
  delete: {
    marginTop: 30,
    textTransform: "none",
  },
  secret: {
    fontSize: 16,
    fontWeight: 700,
    color: "#039be5",
  },
  envVariable: {
    marginBottom: "60px",
  },
  setting: {
    color: "#fff",
    backgroundColor: "#fff",
    marginTop: "40px",
  },
});

export class VariablesTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      variables: [],
      editRowId: -1,
      editSystemRowId: -1,
      editUserRowId: -1,
      fieldError: true,
      systemVariables: [],
      userVariables: [],
      userVarCopy: [],
      systemVarCopy: [],
      changed: false,
      popupOpen: false,
      vars: {},
      projectDetails: null,
    };
  }

  getProjectDetails = (data) => {
    this.setState({
      projectDetails: { ...data },
    });
    return data;
  };

  componentDidMount() {
    this.props.fetchEnvVariables(this.props.environmentDetails?.id);
    this.props.fetchProjectDetailForEnv(
      this.props.environmentDetails.application?.project_id,
      this.getProjectDetails
    );
    this.props.fetchPlugInVersionConfig(
      this.props.environmentDetails?.plugin_version_id
    );
    this.props.getSettingVariableConfig(this.props.environmentDetails?.plugin_version_id);
  }

  componentWillUnmount() {
    this.props.clearPluginVersionConfig();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    var variableInitial = [];
    var systemVariablesInitial = [];
    var userVariablesInitial = [];
    if (nextProps && nextProps.environmentVariables) {
      if (nextProps.environmentVariables) {
        // const v =  nextProps.environmentVariables.system_variables ? nextProps.environmentVariables.system_variables : {}
        // const systemVarType = typeof nextProps.environmentVariables?.system_variables
        // const v =   (systemVarType !== "object" && systemVarType !== "undefined") ? JSON.parse(nextProps.environmentVariables.system_variables) : nextProps.environmentVariables.system_variables;
        // let sysV  =  { ...v }

        const objectKeys = Object.keys(nextProps.environmentVariables);
        if (nextProps.environmentVariables?.system_variables) {
          const fv = flatten(nextProps.environmentVariables?.system_variables);
          Object.keys(fv).forEach((key, index) => {
            let _key = key;
            let isProtected = false;
            let _value = fv[key];
            let _appendedKey = _key.replace(/\./g, ".properties.") + ".hidden";
            isProtected = _.get(
              nextProps?.pluginVersionConfig?.properties,
              _appendedKey
            );
            systemVariablesInitial.push({
              ...{
                id: index,
                key: _key,
                value: _value,
                isProtected,
                isShowPassword: false,
              },
            });
          });
        }

        const userVarType =
          typeof nextProps.environmentVariables?.user_variables;
        const userVariables =
          userVarType !== "object" && userVarType !== "undefined"
            ? JSON.parse(nextProps.environmentVariables.user_variables)
            : nextProps.environmentVariables.user_variables;

        objectKeys.forEach(function (key, index) {
          variableInitial.push({
            id: index,
            key: key,
            value: nextProps.environmentVariables[key],
          });
        });

        userVariables &&
          userVariables !== null &&
          userVariables.forEach((userVar, ind) => {
            let isProtected = false;
            if (userVar.type === "secret") {
              isProtected = true;
            }
            const _variable = {
              id: ind,
              key: userVar.key,
              value: userVar.value,
              isProtected: isProtected,
              isShowPassword: false,
            };
            userVariablesInitial.push(_variable);
          });

        this.setState({
          systemVarCopy: [...systemVariablesInitial],
          userVarCopy: [...userVariablesInitial],
        });
      }
    }

    this.setState({
      variables: variableInitial,
      systemVariables: systemVariablesInitial,
      userVariables: userVariablesInitial,
    });
  }

  // findIsProtected = (key, conf) => {
  //   if(conf){
  //     return Boolean(conf[key]?.hidden)
  //   }
  //   return false
  // }

  handleSaveVariables = (variable) => {
    this.setState({
      vars: variable,
      popupOpen: true,
    });
  };

  handleAgreeHandler = () => {
    // const projectVars = this.state.vars
    // const payload = {
    //   variables: JSON.stringify(projectVars.user_variables),
    //   id: this.props.projectDetails.id,
    // };

    this.props.updateEnvVariables(
      this.props.environmentDetails.id,
      this.state.vars
    );
    this.setState({
      popupOpen: false,
    });
    // this.props.editProjectApiCall(payload, this.props.projectDetails.id);
  };

  handleDisAgreeHandler = () => {
    this.setState({
      popupOpen: false,
    });
  };

  render() {
    const {
      //t,
      //classes,
      //environmentVariables,
      envRole,
      environmentDetails,
      t,
      //classes,
    } = this.props;

    return (
      <div>
        {environmentDetails?.external_secret?.enabled === true ? (
          <div></div>
        ) : (
          <div style={{ marginBottom: "40px" }}>

            <UserVariablesTable
              userVariables={this.state.userVariables}
              systemVariables={this.state.systemVariables}
              handleSave={this.handleSaveVariables}
              updateModule={`environment`}
              supportUserVariable={
                environmentDetails &&
                environmentDetails.service_type > 0
              }
              role={envRole}
              envDetails={environmentDetails}
            />

            <div>
            <SystemVariable/>
            </div>
          </div>
        )}
        {this.state.projectDetails && (
          <UserVariablesTable
            userVariables={this.state.projectDetails.variables ?? []}
            systemVariables={[]}
            handleSave={this.handleSaveVariables}
            updateModule={`project`}
            role={this.props.projectRole}
            title="Global Variables"
            supportUserVariable={true}
            open={this.state.popupOpen}
            disableEdit={true}
          />
        )}

        <ConfirmActionPopup
          open={this.state.popupOpen}
          handleAgree={this.handleAgreeHandler}
          handleDisAgree={this.handleDisAgreeHandler}
          message={t("Environment.VariablesTab.updateEnvVariables")}
          yesText={t("Environment.VariablesTab.yesText")}
          noText={t("Projects.VariablesTab.noText")}
          loading={this.props.updatingVariables}
        />
        {this.props.updatingVariables && (
          <div className="loader-center">
            <BackdropLoader
              message={t("Environment.VariablesTab.updatingVars")}
              data-test="updatingVar-loader"
            />
          </div>
        )}
        {this.props.fetchingEnvVar && (
          <BackdropLoader
            message={t("Environment.VariablesTab.updatingVars")}
            data-test="fetchingVar-loader"
          />
        )}
      </div>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  environmentDetails: state.EnvironmentReducer.environmentDetails,
  environmentVariables: state.EnvironmentReducer.environmentVariables,
  envRole: state.EnvironmentReducer.envRole,
  updatingVariables: state.EnvironmentReducer.updatingVariables,
  pluginVersionConfig: state.EnvironmentReducer.pluginVersionConfig,
  fetchingEnvVar: state.EnvironmentReducer.fetchingEnvVar,
  projectRole: state.ProjectReducer.projectRole,
  settingConfig: state.EnvironmentReducer.settingConfig,
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch) => {
  return {
    fetchEnvVariables: (id) => dispatch(fetchEnvVariables(id)),
    updateEnvVariables: (id, payload) =>
      dispatch(updateEnvVariables(id, payload)),
    fetchPlugInVersionConfig: (id) => dispatch(fetchPlugInVersionConfig(id)),
    clearPluginVersionConfig: () => dispatch(clearPluginVersionConfig()),
    fetchProjectDetailForEnv: (id, callBack) =>
      dispatch(fetchProjectDetailForEnv(id, callBack)),
    getSettingVariableConfig: (pid) => dispatch(getSettingVariableConfig(pid)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(withStyles(useStyles)(withTranslation()(VariablesTab)));
