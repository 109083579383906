import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from "react-i18next";
import { connect, ConnectedProps } from 'react-redux';
import { createEnv } from '../../redux/actions';
import ResourcePaper from '../../../../components/resourceenvironment/ResourcePaper';
import BackdropLoader from '../../../../components/loader/BackdropLoader';
import { CreateApp_TemplateTypes } from '../../../../constants/enums';
import { Dispatch } from "redux";
import { createStyles } from "@material-ui/core";
import { History } from 'history';
import { SystemDetail } from '../../../../models/Environment.model';

/* istanbul ignore next */
const useStyles = () => createStyles({
    actions: {
        display: "flex",
        justifyContent: 'center'
    },
    header: {
        display: "flex", 
        justifyContent: "flex-end"
    }
});

type TriggerPayload = {
    events: string
}

interface Props extends WithTranslation, PropsFromRedux {
    completeData: any,
    updateBlockInfo:()=>void,
    history: History,
    activeStep: number,
    goBack:(step: number)=>void,
    handleChange:(stepNumber: number, data: any)=>void
}

type State = {
    currentStep : number,
    systemDetail : SystemDetail|{},
}
  
export class Resources extends Component<Props, State> {
    constructor(props: Props){
        super(props);
        this.state = {
            currentStep : 2,
            systemDetail : {},
        }
    }

    // componentDidMount(){
        
    // }

    // handlePVSupport = (e)=> {
    //     this.setState({
    //         support_pv: e.target.checked
    //     })
    // }

    handleContinue = () => {
        // if(this.state.systemDetail && !this.state.systemDetail.support_pv){
        //     this.setState({
        //         systemDetails: {...this.state.systemDetail, storage: {}},
        //     })
        // }
        // this.props.handleChange(this.state.currentStep, this.state)
        const { completeData } = this.props;
        const  externalSecret= completeData.external_secret
        const externalLogger = completeData.external_logger;
        let payload: any = {
            name : completeData.systemDetail.environment.trim(),
            application_id: completeData.application_id,
            resource_id: completeData.systemDetail.resource.id,
            plugin_version_id: completeData.versionId,
            version: completeData.version,
            other_version: completeData.other_version,
            replicas: completeData.systemDetail.replicas,
            variables: {...completeData.generalsformData},
            user_variables: [...completeData.user_variables],
            git_branch: completeData.service_type === CreateApp_TemplateTypes.git ? completeData.systemDetail.gitBranch : "",
            image_tag: completeData.service_type === CreateApp_TemplateTypes.cr ? completeData.systemDetail.gitBranch : "",
            pvc: {
                enabled: completeData.systemDetail.support_pv,
                path: completeData.systemDetail.storage && completeData.systemDetail.storage.path,
                size: completeData.systemDetail.storage && completeData.systemDetail.storage.size,
                storageClass: completeData.systemDetail.storage && completeData.systemDetail.storage.storageClass
            },

            auto_scaler: {
                enabled: completeData.systemDetail.auto_scaler?.enabled,
                horizontal_pod_autoscaler: completeData.systemDetail.auto_scaler?.enabled?((completeData.service_type === CreateApp_TemplateTypes.default && completeData.systemDetail.auto_scaler.rwxEnable === true) ||
                completeData.service_type > 0) ? {...completeData.systemDetail.auto_scaler.horizontal_pod_autoscaler} : undefined:null,
                vertical_pod_autoscaler: completeData.systemDetail.auto_scaler?.enabled?(completeData.service_type === CreateApp_TemplateTypes.default && (completeData.systemDetail.auto_scaler.rwxEnable === false || 
                completeData.systemDetail.auto_scaler.rwxEnable === undefined )) ? {...completeData.systemDetail.auto_scaler.vertical_pod_autoscaler} : undefined:null,
                advanced_scheduling: completeData.systemDetail.auto_scaler?.enabled?((completeData.service_type === CreateApp_TemplateTypes.default && completeData.systemDetail.auto_scaler.rwxEnable === true) ||
                completeData.service_type > 0) ? {...completeData.systemDetail.auto_scaler.advanced_scheduling} : undefined:null,
            },

            // horizontal_pod_autoscaler: {...completeData.systemDetail.horizontal_pod_autoscaler},
            InitContainers: completeData.InitContainers ?? [],
            scripts: completeData.scripts ?? {  build: "", run : ""},
            deployment_strategy: completeData.deployment_strategy ?? null,
            prevent_default_build: completeData.prevent_default_build,
            apply_immediately: completeData.apply_immediately,
            load_balancer_id: completeData.systemDetail.enable_loadbalancer ? completeData.systemDetail.load_balancer_id : null,
            setting: completeData.setting,
        }
        payload = completeData.external_secret?.enabled?{...payload, external_secret:externalSecret}:payload;
        payload = completeData.external_logger?.enabled?{...payload, external_logging:externalLogger}:payload;
        let _triggerEvents = "";
        if(completeData.triggers)
        {
            _triggerEvents = completeData.triggers.isPush ? "push" : '';
            if (completeData.triggers.isTag)
                _triggerEvents = (_triggerEvents ? _triggerEvents + "," : '') + "release";
        }
        const triggerPayload = _triggerEvents ? { events: _triggerEvents } : null;
        this.props.updateBlockInfo();
        this.props.createEnvAction(payload, triggerPayload, this.props.history)
    }

    handleResourceUpdate = (systemDetail: SystemDetail) => {
        this.setState({
            systemDetail,
        }, () => {
            this.props.handleChange(this.state.currentStep, this.state)
        })
    }

    render () {
        const { t } = this.props;
        if (this.props.activeStep !== this.state.currentStep) {
            return null
        }
        return (
            <div data-test="main-container">          
                <ResourcePaper 
                  resources={this.props.resourcesList} 
                  handleResourceUpdate={this.handleResourceUpdate} 
                  loadSource={1} 
                  handleButton = {this.handleContinue}
                  defaultEnvName={this.props.completeData && this.props.completeData.systemDetail ? this.props.completeData.systemDetail.environment : "" }
                  defaultResource={this.props.completeData && this.props.completeData.systemDetail && this.props.completeData.systemDetail.resource ? this.props.completeData.systemDetail.resource : null }
                  defaultReplicas={this.props.completeData && this.props.completeData.systemDetail ? this.props.completeData.systemDetail.replicas : "" }
                  defaultBranch = {this.props.completeData && this.props.completeData.systemDetail ? this.props.completeData.systemDetail.gitBranch : "" }
                  availableResources = { this.props.appAvailableResource }
                  //gitRepository = { this.props.completeData && this.props.completeData.gitRepository }
                  service_type={this.props.completeData && this.props.completeData.service_type}
                  gitBranches = { this.props.gitBranches }
                  buttonName={t('Environment.Resources.createEnv')}
                  appDetails={this.props.appDetails}
                  goBack={this.props.goBack}
                  activeStep={this.props.activeStep}
                  data-test="resource-paper"
                  rwxEnable = {this.props.completeData && this.props.completeData.generalsformData && this.props.completeData.generalsformData.rwxEnable}
                  auto_scaler = {this.props.completeData && this.props.completeData.systemDetail ? this.props.completeData.systemDetail.auto_scaler : "" }
                />
                {this.props.creatingEnv && <BackdropLoader message={t('Environment.Resources.creatingEnv')} data-test="creating-backdrop" /> }
            </div>
        )
    }
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
    appDetails: state.AppsReducer.appDetails,
    resourcesList: state.EnvironmentReducer.resourcesList,
    appAvailableResource: state.AppsReducer.appAvailableResource,
    gitBranches: state.AppsReducer.gitBranches,
    creatingEnv: state.EnvironmentReducer.creatingEnv
})

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    createEnvAction : (jsonBody: any, triggerPayload: TriggerPayload|null, history: History) => dispatch(createEnv(jsonBody, triggerPayload, history)),
    
  }
}

const connector = connect(mapStateToProps, mapDispatchtoProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withStyles(useStyles)(withTranslation()(Resources)));